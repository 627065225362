import Dashboard from '../pages/dashboard';

// export const PROJECT_URL = 'http://localhost:3000/';
export const PROJECT_URL = 'https://blondie.icrm.ro/';

// export const API_URL = 'http://localhost:8000/api';
export const API_URL = 'https://blondie-api.icrm.ro/api';

import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import dashboardSVG from '../assets/svg/dashboard.svg';
import fileSVG from '../assets/svg/file-regular.svg';
import Cases from '../pages/cases';
import Icon from '@mui/material/Icon';

export const STYLES = {
  PINK: 'btn--pink',
  TRANSPARENT: 'btn--transparent',
  GRAY: 'btn--gray',
};

export const patientRelationship = [
  { id: 'patientRelationshipPărinte', name: 'Părinte', value: 'parent' },
  {
    id: 'patientRelationshipTutore legal',
    name: 'Tutore legal',
    value: 'tutor',
  },
  {
    id: 'patientRelationshipMedic / Cadru medical',
    name: 'Medic / Cadru medical',
    value: 'doctor',
  },
  {
    id: 'patientRelationshipReprezentant autoritate publică',
    name: 'Reprezentant autoritate publică',
    value: 'representative',
  },
  {
    id: 'patientRelationshipOther',
    name: 'other',
    value: 'other',
    other: true,
  },
];

export const patientRepresentativeDocumentTypeFields = [
  {
    id: 'patientRepresentativeDocumentTypeID',
    name: 'C.I',
    value: 'idCard',
  },
  {
    id: 'patientRepresentativeDocumentTypePassport',
    name: 'Pașaport',
    value: 'passport',
  },
];

export const patientRepresentativeDocumentTypeMapped = {
  idCard: 'C.I',
  passport: 'Pașaport',
};

export const patientRelationshipMapped = {
  parent: 'Părinte',
  tutor: 'Tutore legal',
  doctor: 'Medic / Cadru medical',
  representative: 'Reprezentant autoritate publică',
};

export const patientRequirement = [
  {
    id: 'patientRequirementA doua opinie medicală',
    name: 'A doua opinie medicală',
    value: 'second_medical_opinion',
  },
  {
    id: 'patientRequirementTransport aerian medical',
    name: 'Transport aerian medical',
    value: 'air_transport',
  },
  {
    id: 'patientRequirementTransport terestru medical',
    name: 'Transport terestru medical',
    value: 'land_transport',
  },
  {
    id: 'patientRequirementNu știu încă',
    name: 'Nu știu încă',
    value: 'dont_know',
  },
];

export const patientRequirementMapped = {
  second_medical_opinion: 'A doua opinie medicală',
  air_transport: 'Transport aerian medical',
  land_transport: 'Transport terestru medical',
  dont_know: 'Nu știu încă',
};

export const patientTreatmentInRomaniaFields = [
  {
    id: 'patientTreatmentInRomaniaDa',
    name: 'Da',
    value: 'yes',
  },
  {
    id: 'patientTreatmentInRomaniaNu',
    name: 'Nu',
    value: 'no',
  },
  {
    id: 'patientTreatmentInRomaniaNu știu',
    name: 'Nu știu',
    value: 'dont_know',
  },
  {
    id: 'patientTreatmentInRomaniaNu încă',
    name: 'Nu încă',
    value: 'not_yet',
  },
];

export const patientTreatmentInRomaniaFieldsMapped = {
  yes: 'Da',
  no: 'Nu',
  dont_know: 'Nu știu',
  not_yet: 'Nu încă',
};

export const patientLocationFields = [
  {
    id: 'patientLocationInternat în spital',
    name: 'Internat în spital',
    value: 'hospitalized',
  },
  {
    id: 'patientLocationLa domiciliu',
    name: 'La domiciliu',
    value: 'home',
  },
  {
    id: 'patientLocationOther',
    name: 'other',
    value: 'other',
    other: true,
  },
];

export const patientLocationMapped = {
  hospitalized: 'Internat în spital',
  home: 'La domiciliu',
};

export const sbpTwoPatientTreatmentInRomaniaFields = [
  {
    id: 'patientTreatmentOutsideRomaniaDa',
    name: 'Da',
    value: 'yes',
  },
  {
    id: 'patientTreatmentOutsideRomaniaNu',
    name: 'Nu',
    value: 'no',
  },
  {
    id: 'patientTreatmentOutsideRomaniaNu știu',
    name: 'Nu știu',
    value: 'dont_know',
  },
  {
    id: 'patientTreatmentOutsideRomaniaNu încă',
    name: 'Nu încă',
    value: 'not_yet',
  },
];

export const clinicAcceptanceFields = [
  {
    id: 'clinicAcceptanceFieldsDa',
    name: 'Da',
    value: 'yes',
  },
  {
    id: 'clinicAcceptanceFieldsNu',
    name: 'Nu',
    value: 'no',
  },
  {
    id: 'clinicAcceptanceFieldsNu știu',
    name: 'Nu știu',
    value: 'dont_know',
  },
  {
    id: 'clinicAcceptanceFieldsNu încă',
    name: 'Nu încă',
    value: 'not_yet',
  },
];

export const clinicAcceptanceMapped = {
  yes: 'Da',
  no: 'Nu',
  dont_know: 'Nu știu',
  not_yet: 'Nu încă',
};

export const sbpTwoAirTransportAttendantFields = [
  { id: 'sbpTwoAirTransportAttendantMama', name: 'Mama', value: 'mother' },
  { id: 'sbpTwoAirTransportAttendantTata', name: 'Tata', value: 'father' },
  {
    id: 'sbpTwoAirTransportAttendantNu știu încă',
    name: 'Nu știu încă',
    value: 'dont_know',
  },
  { id: 'sbpTwoAirTransportAttendantSotia', name: 'Sotia', value: 'wife' },
  {
    id: 'sbpTwoAirTransportAttendantMama și tata',
    name: 'Mama și tata',
    value: 'mother_father',
  },
  {
    id: 'sbpTwoAirTransportAttendantAsistentul maternal profesionist Scutaru Livia',
    name: 'Asistentul maternal profesionist Scutaru Livia',
    value: 'assistant',
  },
  { id: 'sbpTwoAirTransportAttendantother', name: 'other', value: 'other' },
];

export const sbpTwoPatientTravelDocumentFields = [
  {
    id: 'sbpTwoPatientTravelDocumentPașaport',
    name: 'Pașaport',
    value: 'passport',
  },
  { id: 'sbpTwoPatientTravelDocumentCI', name: 'CI', value: 'CI' },
  {
    id: 'sbpTwoPatientTravelDocumentNu știu încă',
    name: 'Nu știu încă',
    value: 'dont_know',
  },
  {
    id: 'sbpTwoPatientTravelDocumentNiciuna din variante',
    name: 'Niciuna din variante',
    value: 'none',
  },
];

export const patientTravelDocumentMapped = {
  none: 'Niciuna din variante',
  dont_know: 'Nu știu încă',
  CI: 'CI',
  passport: 'Pașaport',
};

export const patientAttendantTravelDocumentMapped = {
  none: 'Niciuna din variante',
  dont_know: 'Nu știu încă',
  CI: 'CI',
  passport: 'Pașaport',
};

export const sbpTwoPatientAttendantTravelDocumentFields = [
  {
    id: 'sbpTwoPatientAttendantTravelDocumentPașaport',
    name: 'Pașaport',
    value: 'passport',
  },
  { id: 'sbpTwoPatientAttendantTravelDocumentCI', name: 'CI', value: 'CI' },
  {
    id: 'sbpTwoPatientAttendantTravelDocumentNu știu încă',
    name: 'Nu știu încă',
    value: 'dont_know',
  },
  {
    id: 'sbpTwoPatientAttendantTravelDocumentNiciuna din variante',
    name: 'Niciuna din variante',
    value: 'none',
  },
];
export const sbpTwoTransportToClinicFields = [
  {
    id: 'sbpTwoTransportToClinicFieldsFormular E112',
    name: 'Formular E112',
    value: 'E112',
  },
  {
    id: 'sbpTwoTransportToClinicFieldsFormular O50',
    name: 'Formular O50',
    value: 'O50',
  },
  {
    id: 'sbpTwoTransportToClinicFieldsNu știu ce înseamnă',
    name: 'Nu știu ce înseamnă',
    value: 'dont_know_what_it_means',
  },
  {
    id: 'sbpTwoTransportToClinicFieldsÎncercăm prin E112, să vedem dacă reușim',
    name: 'Încercăm prin E112, să vedem dacă reușim',
    value: 'E112_question',
  },
  {
    id: 'sbpTwoTransportToClinicFieldseste vorba de intoarcere in tara dupa terminarea tratamentului',
    name: 'este vorba de intoarcere in tara dupa terminarea tratamentului',
    value: 'return_to_the_country',
  },
  {
    id: 'sbpTwoTransportToClinicFieldsTatal are rezidenta, serviciu si este asigurat in tara de destinatie',
    name: 'Tatal are rezidenta, serviciu si este asigurat in tara de destinatie',
    value: 'father_residency',
  },
  {
    id: 'sbpTwoTransportToClinicFieldsFonduri proprii',
    name: 'Fonduri proprii',
    value: 'own_funds',
  },
  {
    id: 'sbpTwoTransportToClinicFieldsother',
    name: 'other',
    value: 'other',
  },
];

export const sbpTwoMedicalAirTransportNeedsFields = [
  {
    id: 'sbpTwoMedicalAirTransportNeedsFieldsAmbulanță de la locul de plecare spre aeroport',
    name: 'Ambulanță de la locul de plecare spre aeroport',
    value: 'ambulance_departure',
  },
  {
    id: 'sbpTwoMedicalAirTransportNeedsFieldsAmbulanță de la aeroport spre clinica din străinătate',
    name: 'Ambulanță de la aeroport spre clinica din străinătate',
    value: 'ambulance_airport',
  },
  {
    id: 'sbpTwoMedicalAirTransportNeedsFieldsNu știu încă',
    name: 'Nu știu încă',
    value: 'dont_know',
  },
  {
    id: 'sbpTwoMedicalAirTransportNeedsFieldsother',
    name: 'other',
    value: 'other',
  },
];

export const transportNeedsMapped = {
  dont_know: 'Nu știu încă',
  ambulance_airport: 'Ambulanță de la aeroport spre clinica din străinătate',
  ambulance_departure: 'Ambulanță de la locul de plecare spre aeroport',
};

export const sbpTwoMedicalAirTransportNotesFields = [
  {
    id: 'sbpTwoMedicalAirTransportNotesFieldsNu',
    name: 'Nu',
    value: 'no',
  },
  {
    id: 'sbpTwoMedicalAirTransportNotesFieldsother',
    name: 'other',
    value: 'other',
  },
];

export const sbpTwoPatientLocationFields = [
  {
    id: 'sbpTwoPatientLocationFieldsInternat în spital',
    name: 'Internat în spital',
    value: 'hospitalized',
  },
  {
    id: 'sbpTwoPatientLocationFieldsLa domiciliu',
    name: 'La domiciliu',
    value: 'home',
  },
  {
    id: 'sbpTwoPatientLocationFieldsother',
    name: 'other',
    value: 'other',
  },
];

export const sbpThreePatientTreatmentInRomaniaFields = [
  {
    id: 'sbpThreePatientTreatmentInRomaniaFieldsDa',
    name: 'Da',
    value: 'yes',
  },
  {
    id: 'sbpThreePatientTreatmentInRomaniaFieldsNu',
    name: 'Nu',
    value: 'no',
  },
  {
    id: 'sbpThreePatientTreatmentInRomaniaFieldsNu știu',
    name: 'Nu știu',
    value: 'dont_know',
  },
  {
    id: 'sbpThreePatientTreatmentInRomaniaFieldsNu încă',
    name: 'Nu încă',
    value: 'not_yet',
  },
];

export const sbpThreeClinicAcceptanceFields = [
  {
    id: 'sbpThreeClinicAcceptanceFieldsDa',
    name: 'Da',
    value: 'yes',
  },
  {
    id: 'sbpThreeClinicAcceptanceFieldsNu',
    name: 'Nu',
    value: 'no',
  },
  {
    id: 'sbpThreeClinicAcceptanceFieldsNu știu',
    name: 'Nu știu',
    value: 'dont_know',
  },
  {
    id: 'sbpThreeClinicAcceptanceFieldsNu încă',
    name: 'Nu încă',
    value: 'not_yet',
  },
];
export const sbpThreeLandTransportAttendantFields = [
  {
    id: 'sbpThreeLandTransportAttendantFieldsMama',
    name: 'Mama',
    value: 'mother',
  },
  {
    id: 'sbpThreeLandTransportAttendantFieldsTata',
    name: 'Tata',
    value: 'father',
  },
  {
    id: 'sbpThreeLandTransportAttendantFieldsNu știu încă',
    name: 'Nu știu încă',
    value: 'dont_know',
  },
  {
    id: 'sbpThreeLandTransportAttendantFieldsother',
    name: 'other',
    value: 'other',
  },
];

export const landTransportAttendantMapped = {
  mother: 'Mama',
  father: 'Tata',
  assistant: 'Asistentul maternal profesionist Scutaru Livia',
  mother_father: 'Mama și tata',
  dont_know: 'Nu știu încă',
};

export const sbpThreePatientTravelDocumentFields = [
  {
    id: 'sbpThreePatientTravelDocumentFieldsPașaport',
    name: 'Pașaport',
    value: 'passport',
  },
  { id: 'sbpThreePatientTravelDocumentFieldsCI', name: 'CI', value: 'CI' },
  {
    id: 'sbpThreePatientTravelDocumentFieldsNu știu încă',
    name: 'Nu știu încă',
    value: 'dont_know',
  },
  {
    id: 'sbpThreePatientTravelDocumentFieldsNiciuna din variante',
    name: 'Niciuna din variante',
    value: 'none',
  },
];

export const sbpThreePatientAttendantTravelDocumentFields = [
  {
    id: 'sbpThreePatientAttendantTravelDocumentFieldsPașaport',
    name: 'Pașaport',
    value: 'passport',
  },
  {
    id: 'sbpThreePatientAttendantTravelDocumentFieldsCI',
    name: 'CI',
    value: 'CI',
  },
  {
    id: 'sbpThreePatientAttendantTravelDocumentFieldsNu știu încă',
    name: 'Nu știu încă',
    value: 'dont_know',
  },
  {
    id: 'sbpThreePatientAttendantTravelDocumentFieldsNiciuna din variante',
    name: 'Niciuna din variante',
    value: 'none',
  },
];
export const sbpThreeTransportToClinicFields = [
  {
    id: 'sbpThreeTransportToClinicFieldsFormular E112',
    name: 'Formular E112',
    value: 'E112',
  },
  {
    id: 'sbpThreeTransportToClinicFieldsFormular O50',
    name: 'Formular O50',
    value: 'O50',
  },
  {
    id: 'sbpThreeTransportToClinicFieldsNu știu ce înseamnă',
    name: 'Nu știu ce înseamnă',
    value: 'dont_know_what_it_means',
  },
  {
    id: 'sbpThreeTransportToClinicFieldsFonduri proprii',
    name: 'Fonduri proprii',
    value: 'own_funds',
  },
  { id: 'sbpThreeTransportToClinicFieldsother', name: 'other', value: 'other' },
];

export const transportToClinicMapped = {
  return_to_the_country:
    'este vorba de intoarcere in tara dupa terminarea tratamentului',
  own_funds: 'Fonduri proprii',
  E112_question: 'Încercăm prin E112',
  O50: 'Formular O50',
  E112: 'Formular E112',
  dont_know_what_it_means: 'Nu știu ce înseamnă',
  father_residency:
    'Tatal are rezidenta, serviciu si este asigurat in tara de destinatie',
};

export const sbpThreeMedicalLandTransportNeedsFields = [
  {
    id: 'sbpThreeMedicalLandTransportNeedsFieldsAmbulanță de la locul de plecare spre aeroport',
    name: 'Ambulanță de la locul de plecare spre aeroport',
    value: 'ambulance_departure',
  },
  {
    id: 'sbpThreeMedicalLandTransportNeedsFieldsAmbulanță de la aeroport spre clinica din străinătate',
    name: 'Ambulanță de la aeroport spre clinica din străinătate',
    value: 'ambulance_airport',
  },
  {
    id: 'sbpThreeMedicalLandTransportNeedsFieldsNu știu încă',
    name: 'Nu știu încă',
    value: 'dont_know',
  },
  {
    id: 'sbpThreeMedicalLandTransportNeedsFieldsother',
    name: 'other',
    value: 'other',
  },
];

export const sbpThreeMedicalLandTransportNotesFields = [
  {
    id: 'sbpThreeMedicalLandTransportNotesFieldsNu',
    name: 'Nu',
    value: 'no',
  },
  {
    id: 'sbpThreeMedicalLandTransportNotesFieldsother',
    name: 'other',
    value: 'other',
  },
];

export const transportNotesMapped = {
  no: 'Nu',
};

export const sbpThreePatientLocationFields = [
  {
    id: 'sbpThreePatientLocationFieldsInternat în spital',
    name: 'Internat în spital',
    value: 'hospitalized',
  },
  {
    id: 'sbpThreePatientLocationFieldsLa domiciliu',
    name: 'La domiciliu',
    value: 'home',
  },
  {
    id: 'sbpThreePatientLocationFieldsother',
    name: 'other',
    value: 'other',
  },
];

export const dataUsageAgreementFields = [
  {
    id: 'agreementFieldsDa',
    name: 'Sunt de acord ca Asociația Blondie să utilizeze toate informațiile furnizate și documentele atașate în soluționarea solicitării depuse de mine prin acest formular.',
    value: 'yes',
  },
  {
    id: 'agreementFieldsNu',
    name: 'Nu sunt de acord ca Asociația Blondie să utilizeze niciuna dintre informațiile furnizate și documentele atașate în soluționarea solicitării depuse de mine prin acest formular.',
    value: 'no',
  },
];

export const dataUsageAgreementFieldsMapped = {
  no: 'Nu',
  yes: 'Da',
};

export const stepFields = {
  1: {
    1: [
      'firstAndLastName',
      'email',
      'phoneNumber',
      'patientRelationship',
      'patientRequirement',
      'patientRelationshipCustomInput',
      'phoneNumber',
      'patientPIN',
      'patientRepresentative',
      'patientRepresentativeEmail',
      'patientRepresentativePhoneNumber',
      'patientRepresentativeAddressStreet',
      'patientRepresentativeAddressCounty',
      'patientRepresentativeAddressCity',
      'patientRepresentativeAddressCitySector',
      'patientRepresentativeAddressStreetNo',
      'patientRepresentativeAddressStreetBlock',
      'patientRepresentativeAddressBlockEntrance',
      'patientRepresentativeAddressBlockFloor',
      'patientRepresentativeAddressBlockApartment',
      'patientRepresentativePIN',
      'patientRepresentativeDocumentType',
      'patientRepresentativeSN',
      'patientRepresentativeSNNo',
      'patientRepresentativeDocumentIssuedBy',
      'patientRepresentativeDocumentIssueDate',
      'patientRepresentativeAge',
      'patientRepresentativeAddressCityBorn',
      'customInputValue',
      'patientFirstAndLastName',
      'patientAge',
      'patientDiagnosis',
      'diagnoses',
    ],
  },
  2: {
    1: [
      // 'patientFirstAndLastName',
      // 'patientAge',
      // 'patientDiagnosis',
      'patientMedicalDocuments',
      'patientHistory',
      'patientTreatmentInRomania',
      'patientLocation',
      // 'diagnoses',
      'patientLocationCustomInput',
    ],
    2: [
      // 'sbpTwoPatientFirstAndLastName',
      // 'sbpTwoPatientAge',
      // 'sbpTwoPatientDiagnosis',
      'sbpTwoPatientMedicalDocuments',
      'sbpTwoPatientHistory',
      'sbpTwoPatientTreatmentInRomania',
      'sbpTwoClinicAcceptance',
      'sbpTwoClinicContact',
      'sbpTwoDepartureAndDestinationAerial',
      'sbpTwoAirTransportAttendant',
      'sbpTwoPatientTravelDocument',
      'sbpTwoPatientAttendantTravelDocument',
      'sbpTwoTransportToClinic',
      'sbpTwoMedicalAirTransportNeeds',
      'sbpTwoMedicalAirTransportNotes',
      'sbpTwoPatientLocation',
      // 'sbpTwoDiagnoses',
      'sbpTwoClinicAppointment',
      'sbpTwoAirTransportAttendantCustomInput',
      'sbpTwoMedicalAirTransportNeedsCustomInput',
      'sbpTwoPatientLocationCustomInput',
      'sbpTwoTransportToClinicCustomInput',
      'sbpTwoMedicalAirTransportNotesCustomInput',
    ],
    3: [
      // 'sbpThreePatientFirstAndLastName',
      // 'sbpThreePatientAge',
      // 'sbpThreePatientDiagnosis',
      'sbpThreePatientMedicalDocuments',
      'sbpThreePatientHistory',
      'sbpThreePatientTreatmentInRomania',
      'sbpThreeClinicContact',
      'sbpThreeDepartureAndDestinationLand',
      'sbpThreeLandTransportAttendant',
      'sbpThreeLandTransportAttendantCustomInput',
      'sbpThreePatientTravelDocument',
      'sbpThreePatientAttendantTravelDocument',
      'sbpThreeTransportToClinic',
      'sbpThreeMedicalLandTransportNotes',
      'sbpThreeMedicalLandTransportNotesCustomInput',
      'sbpThreePatientLocation',
      'sbpThreeClinicAcceptance',
      // 'sbpThreeDiagnoses',
      'sbpThreeClinicAppointment',
      'sbpThreeTransportToClinicCustomInput',
      'sbpThreePatientLocationCustomInput',
    ],
  },
  3: {
    1: [
      'patientHospital',
      'hospitalSection',
      'patientDoctor',
      'doctorContact',
      'hospitalizationDate',
    ],
  },
  4: {
    1: ['dataUsageAgreement'],
  },
};

export const defaultLinks = [
  {
    label: 'Dashboard',
    name: 'Dashboard',
    key: 'Dashboard',
    route: '/',
    // icon: <SpaceDashboardIcon />,
    icon: <Icon fontSize='small'>dashboard</Icon>,
    component: <Dashboard />,
  },
  {
    label: 'Cases',
    name: 'Cases',
    key: 'Cases',
    route: '/cases',
    // icon: <LibraryBooksIcon />,
    icon: <Icon fontSize='small'>assignment</Icon>,
    // component: <Cases />,
  },
];

export const caseStatus = {
  draft: 'în așteptare',
  pending: 'activ',
  completed: 'închis',
};
