import { createSlice } from '@reduxjs/toolkit';
import { defaultLinks } from '../../constants/index.js';

const initialState = { isNavOpen: true, navLinks: defaultLinks };

const navSlice = createSlice({
  name: 'nav',
  initialState,
  reducers: {
    toggleNav(state) {
      state.isNavOpen = !state.isNavOpen;
    },
  },
  extraReducers: (builder) => {},
});

export const navActions = navSlice.actions;

export default navSlice.reducer;
