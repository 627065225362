import React from 'react';
// import PropTypes from 'prop-types';
import { Navigate, Outlet } from 'react-router';

const ConditionalRedirect = ({ redirects = {}, isLoggedIn = false }) => {
  const firstRedirect = Object.keys(redirects).find(
    (redirect) => redirects[redirect] === true,
  );

  // if (!isLoggedIn && firstRedirect) {
  //   return <Navigate to={firstRedirect} replace />;
  // }
  // return <Outlet />;

  return (
    <>
      {
        //   !isLoggedIn ? (
        //   <Outlet />
        // ) :
        firstRedirect ? <Navigate to={firstRedirect} replace /> : <Outlet />
      }
    </>
  );
};

export default ConditionalRedirect;
