import React from 'react';
import { dataUsageAgreementFields } from '../../constants/index.js';
import RadioButtonsList from '../radio-buttons-list/index.jsx';

const CaseStepFour = ({
  register,
  errors,
  setValue,
  setDirtyFields,
  watch,
}) => {
  const dataUsageAgreement = watch('dataUsageAgreement');
  const onChange = (e) => {
    setDirtyFields((prevState) => ({
      ...prevState,
      [e.target.name]: true,
    }));
    setValue(e.target.name, e.target.value);
  };

  return (
    <RadioButtonsList
      register={register}
      radioGroupValue={dataUsageAgreement}
      onChange={onChange}
      error={errors.dataUsageAgreement && errors.dataUsageAgreement.message}
      name='dataUsageAgreement'
      className='add-case__location__section'
      // title='Pacientul deține următoarele documente de călătorie'
      list={dataUsageAgreementFields}
    />
  );
};
export default CaseStepFour;
