import React, { useEffect } from 'react';
import Input from '../../input/index.jsx';

const CaseSubstepOne = ({
  register,
  errors,
  trigger,
  setValue,
  setDirtyFields,
  watch,
  substep,
  handleFileUpload,
  handleRemoveFile,
  uploadedFiles,
  control,
}) => {
  const patientTreatmentInRomania = watch('patientTreatmentInRomania');
  const patientLocation = watch('patientLocation');
  const patientLocationCustomInput = watch('patientLocationCustomInput', '');

  const onInputChange = (e) => {
    trigger(e.target.name);
    setDirtyFields((prevState) => ({
      ...prevState,
      [e.target.name]: true,
    }));
    // setValue('radioGroup-customInput', value);
  };

  return (
    <>
      <Input
        placeholder='Your answer'
        type='text'
        name='patientHospital'
        label='În ce spital este internat pacientul în acest moment?'
        error={errors.patientHospital}
        {...register('patientHospital', {
          onChange: (e) => onInputChange(e),
        })}
      />
      <Input
        // placeholder='Nume si prenume'
        type='text'
        name='hospitalSection'
        label='În ce sectie?'
        error={errors.hospitalSection}
        {...register('hospitalSection', {
          onChange: (e) => onInputChange(e),
        })}
      />
      <Input
        // placeholder='Nume si prenume'
        type='text'
        name='patientDoctor'
        label='Cine este doctorul curant?'
        error={errors.patientDoctor}
        {...register('patientDoctor', {
          onChange: (e) => onInputChange(e),
        })}
      />
      <Input
        // placeholder='Nume si prenume'
        type='text'
        name='doctorContact'
        label='Ne puteți lasa un contact al doctorului curant?'
        optionalText='Nr. de telefon sau adresa de e-mail'
        error={errors.doctorContact}
        {...register('doctorContact', {
          onChange: (e) => onInputChange(e),
        })}
      />
      <Input
        // placeholder='Nume si prenume'
        type='date'
        name='hospitalizationDate'
        label='Care este data la care a fost internat pacientul?'
        error={errors.hospitalizationDate}
        {...register('hospitalizationDate', {
          onChange: (e) => onInputChange(e),
        })}
      />
    </>
  );
};

export default CaseSubstepOne;
