// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Grid from '@mui/material/Grid';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';

// Material Dashboard 2 React example components
import DefaultNavbar from 'examples/Navbars/DefaultNavbar';
import PageLayout from 'examples/LayoutContainers/PageLayout';

// Authentication pages components
import Footer from 'layouts/authentication/components/Footer';

const dynamicGridProps1 = {
  xs: 11,
  sm: 9,
  md: 5,
  lg: 4,
  xl: 3,
};

const dynamicGridProps2 = {
  xs: 11,
  sm: 8,
  md: 8,
  lg: 8,
  xl: 8,
};

function BasicLayout({ image, children, wideForm = false }) {
  const props = wideForm ? dynamicGridProps2 : dynamicGridProps1;
  return (
    <PageLayout
      style={{ marginTop: '3rem', marginBottom: '3rem', overflowX: 'visible' }}
    >
      <MDBox
        position='absolute'
        width='100%'
        minHeight='100vh'
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) =>
            image &&
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6),
            )}, url(${image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      />
      <MDBox px={1} width='100%' height='100vh' mx='auto'>
        <Grid
          container
          spacing={1}
          justifyContent='center'
          alignItems='center'
          height='100%'
        >
          <Grid item {...props}>
            {children}
          </Grid>
        </Grid>
      </MDBox>
    </PageLayout>
  );
}

// Typechecking props for the BasicLayout
BasicLayout.propTypes = {
  image: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default BasicLayout;
