import React, { useEffect, useRef } from 'react';
import { useFieldArray } from 'react-hook-form';
import Input from '../../input/index.jsx';

import MDButton from '../../MDButton';

import InputFile from '../../input-file/index.jsx';
import {
  patientLocationFields,
  patientRelationship,
  patientRequirement,
  patientTreatmentInRomaniaFields,
} from '../../../constants/index.js';
import RadioButtonsList from '../../radio-buttons-list/index.jsx';

const CaseSubstepOne = ({
  register,
  errors,
  trigger,
  setValue,
  setDirtyFields,
  watch,
  substep,
  handleFileUpload,
  handleRemoveFile,
  uploadedFiles,
  // control,
}) => {
  // const { fields, append, remove } = useFieldArray({
  //   control,
  //   name: 'diagnoses',
  // });

  const patientTreatmentInRomania = watch('patientTreatmentInRomania');
  const patientLocation = watch('patientLocation');
  const patientLocationCustomInput = watch('patientLocationCustomInput', '');

  const onChange = (e) => {
    setDirtyFields((prevState) => ({
      ...prevState,
      [e.target.name]: true,
    }));
    setValue(e.target.name, e.target.value);
    trigger(e.target.name);
    if (e.target.name === 'patientLocationCustomInput') {
      setValue('patientLocation', 'other');
      setDirtyFields((prevState) => ({
        ...prevState,
        ['patientLocation']: true,
      }));
    }
  };

  const onInputChange = (e) => {
    trigger(e.target.name);
    setDirtyFields((prevState) => ({
      ...prevState,
      [e.target.name]: true,
    }));
    // setValue('radioGroup-customInput', value);
  };

  // const handleDiagnosisChange = async (index, value) => {
  //   setValue(`diagnoses.${index}.diagnosisText`, value);

  //   // Remove the last input if the current input is empty or its value has changed to ''
  //   if (value === '' && index === fields.length - 1) {
  //     remove(index - 1);
  //   }

  //   // Trigger validation for the current input
  //   await trigger(`diagnoses.${index}.diagnosisText`);
  // };

  return (
    <>
      <InputFile
        id='patientMedicalDocuments'
        name='patientMedicalDocuments'
        label='Vă rugăm să atașați documentele medicale:'
        optionalText='Ex: scrisoare medicală, analize de sânge, bilet de externare, ecografii, CT, RMN, etc.'
        handleRemoveFile={handleRemoveFile}
        onChange={(event) => {
          handleFileUpload('patientMedicalDocuments', event.target.files);
        }}
        uploadedFiles={uploadedFiles}
        error={errors.patientMedicalDocuments}
        multiple={true}
        message='Files'
      />

      <Input
        type='text'
        name='patientHistory'
        label='Istoricul pacientului pe scurt'
        optionalText='Ex: Cum a debutat afecțiunea, când, tratamente și internările precedente, etc.'
        error={errors.patientHistory}
        {...register('patientHistory', {
          onChange: (e) => onInputChange(e),
        })}
      />

      <RadioButtonsList
        register={register}
        radioGroupValue={patientTreatmentInRomania}
        onChange={onChange}
        error={
          errors.patientTreatmentInRomania &&
          errors.patientTreatmentInRomania.message
        }
        name='patientTreatmentInRomania'
        className='add-case__patient-relationship__section'
        title='Din informațiile pe care le dețineți la momentul actual, tratamentul se poate efectua pe teritoriul României?'
        list={patientTreatmentInRomaniaFields}
      />

      <RadioButtonsList
        register={register}
        radioGroupValue={patientLocation}
        customInputValue={patientLocationCustomInput}
        onChange={onChange}
        error={
          (errors.patientLocation && errors.patientLocation.message) ||
          (patientLocation === 'other' &&
            patientLocationCustomInput === '' &&
            'Required')
        }
        name='patientLocation'
        className='add-case__location__section'
        title='Unde se află în acest moment pacientul?'
        list={patientLocationFields}
      />
    </>
  );
};

export default CaseSubstepOne;
