import { useState, useEffect, useMemo } from 'react';

// react-router components
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

// @mui material components
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Icon from '@mui/material/Icon';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';

// Material Dashboard 2 React example components
import Sidenav from 'examples/Sidenav';
import Configurator from 'examples/Configurator';

// Material Dashboard 2 React themes
import theme from 'assets/theme';
import themeRTL from 'assets/theme/theme-rtl';

// Material Dashboard 2 React Dark Mode themes
import themeDark from 'assets/theme-dark';
import themeDarkRTL from 'assets/theme-dark/theme-rtl';

// RTL plugins
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

// Material Dashboard 2 React routes
// import routes from 'routes';

// Material Dashboard 2 React contexts
import {
  useMaterialUIController,
  setMiniSidenav,
  setOpenConfigurator,
} from 'context';

// Images
import brandWhite from 'assets/images/logo-ct.png';
import brandDark from 'assets/images/logo-ct-dark.png';
import AppRoutes from './routes';
import { defaultLinks } from './constants';
import ConditionalRedirect from './components/conditional-redirect';
import Signup from './pages/signup';
import Login from './pages/login';
import Dashboard1 from './layouts/dashboard';
import Dashboard from './pages/dashboard';
import AddCase from './pages/add-case';
import Cases from './pages/cases';
import Home from './pages/home';
import Case from './pages/case';
import FourOFour from './pages/404';
import SignIn from './layouts/authentication/sign-in';
import RequestAddCase from './pages/request-add-case';
import SignUp from './layouts/authentication/sign-up';
import routes from './routes';
import { routesList } from './routes.js';

function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  const isLoggedIn = !!localStorage.getItem('token');
  // const location = useLocation();
  const routes = getRoutes({ isLoggedIn });

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: 'rtl',
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute('dir', direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === 'dashboard' && (
        <Sidenav
          color={sidenavColor}
          brand={
            (transparentSidenav && !darkMode) || whiteSidenav
              ? brandDark
              : brandWhite
          }
          brandName='Material Dashboard 2'
          routes={routesList}
          // routes={defaultLinks}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        />
      )}
      <Routes>
        {routes.map((r) => (
          <Route
            key={r.path}
            element={
              <ConditionalRedirect
                redirects={r.redirects}
                isLoggedIn={isLoggedIn}
              />
            }
          >
            <Route key={r.path} path={r.path} element={r.element} />
          </Route>
        ))}
        <Route path='*' element={<Navigate to='/404' replace />} />
      </Routes>{' '}
    </ThemeProvider>
  );
}

const getRoutes = ({ isLoggedIn }) => {
  const authenticatedRedirect = {
    '/login': !isLoggedIn,
  };

  return [
    {
      path: '/sign-up',
      element: <SignUp />,
      // element: <Signup />,
      redirects: {
        '/': isLoggedIn,
      },
    },
    {
      path: '/login',
      // element: <Login />,
      element: <SignIn />,
      redirects: {
        '/': isLoggedIn,
      },
    },
    {
      path: '/contact',
      element: <RequestAddCase />,
    },
    // {
    //   path: '/',
    //   element: <Dashboard />,
    //   redirects: {
    //     ...authenticatedRedirect,
    //   },
    // },
    {
      path: '/playground-flaviu',
      element: <Dashboard1 />,
    },
    {
      path: '/add-case',
      element: <AddCase />,
      redirects: {
        ...authenticatedRedirect,
      },
    },
    {
      path: '/edit-case/:id',
      element: <AddCase />,
      redirects: {
        ...authenticatedRedirect,
      },
    },
    {
      path: '/',
      element: <Home />,
      redirects: {
        ...authenticatedRedirect,
      },
    },
    {
      path: '/cases',
      element: <Cases />,
      redirects: {
        ...authenticatedRedirect,
      },
    },
    {
      path: '/cases/:id',
      element: <Case />,
      redirects: {
        ...authenticatedRedirect,
      },
    },
    {
      path: '/404',
      element: <FourOFour />,
      redirects: {
        ...authenticatedRedirect,
      },
    },
  ];
};

export default App;
