import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Input from '../../components/input';
import Button from '../../components/button';

import { updatedUserData } from '../../redux/actions/user.js';

import './style.scss';
import { STYLES as STYLE } from '../../constants/index.js';
import { useLoginMutation } from '../../redux/api/user.js';
import { useDispatch } from 'react-redux';

const schema = Yup.object().shape({
  email_or_phone: Yup.string()
    .matches(
      /^(?:\+40|0)[1-9][0-9]{8}$|^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      'Please enter a valid email or Romanian phone number',
    )
    .required('This field is required'),
  password: Yup.string().required('Password is required'),
});

const validateEmail = () => {
  return Yup.string().email('Invalid Email');
};

const validatePhone = () => {
  return Yup.string().matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*[\]{}()?"\\,><':;|_~`=+-])[a-zA-Z\d!@#$%^&*[\]{}()?"\\,><':;|_~`=+-]{8,99}$/,
  );
};

const LogIn = () => {
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [triggerLogin, { isLoading }] = useLoginMutation();

  const onSubmit = async (data) => {
    try {
      const param = data.email_or_phone.includes('@')
        ? { email: data.email_or_phone }
        : { phone: data.email_or_phone };
      const loginData = await triggerLogin({
        ...param,
        password: data.password,
      }).unwrap();
      localStorage.setItem('token', loginData);
      navigate('/');
    } catch (error) {
      setError('email', {
        type: 'server side',
      });
      setError('password', {
        type: 'server side',
        message: error.data.message,
      });
    }
  };

  return (
    <section className='login'>
      <form className='form' onSubmit={handleSubmit(onSubmit)}>
        <Input
          // onFocus={resetLogInErrors}
          placeholder='Email/phone'
          type='text'
          name='email_or_phone'
          label='Email/phone'
          error={errors.email_or_phone}
          {...register('email_or_phone')}
        />
        <Input
          // onFocus={resetLogInErrors}
          placeholder='Password'
          type='password'
          name='password'
          label='Password'
          error={errors.password}
          {...register('password')}
        />
        <Button type='submit' style={STYLE.PINK}>
          Log In
        </Button>
      </form>
      <p className='sign-up__button'>
        Don&#39;t have an account? <a href='/sign-up'>Sign Up</a>
      </p>
    </section>
  );
};

export default LogIn;
