import React from 'react';
import moment from 'moment';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Svg,
  Path,
  Image,
} from '@react-pdf/renderer';
import blondieLogo from '../../assets/images/blondie-logo.png';
import { patientRepresentativeDocumentTypeFields } from '../../constants';

Font.register({
  family: 'Open Sans',
  fonts: [
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf',
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf',
      fontWeight: 600,
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 64,
  },
  title: {
    fontSize: 12,
    marginBottom: 16,
    textAlign: 'center',
    // fontFamily: 'CustomFontBold',
    // fontWeight: 'bold',

    fontFamily: 'Open Sans',
    // fontSize: 20,
    fontWeight: 600,
  },
  subTitle: {
    fontSize: 12,
    marginBottom: 12,
    fontFamily: 'Open Sans',
    // fontSize: 20,
    fontWeight: 600,
  },

  section: {
    marginBottom: 10,
  },
  sectionTextParagraph: {
    fontSize: 12,
    marginBottom: 5,
    textIndent: 16,
    fontFamily: 'CustomFont',
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  sectionText: {
    fontSize: 12,
    marginBottom: 8,
    fontFamily: 'CustomFont',
  },
  firstText: {
    textAlign: 'center',
  },
  checkboxLabel: {
    fontSize: 12,
    marginLeft: 10,
    fontFamily: 'CustomFont',
  },

  data: {
    fontSize: 12,
    fontFamily: 'CustomFont',
  },
  checkboxContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  checkbox: {
    width: 12,
    height: 12,
    marginRight: 5,
    justifyContent: 'center',
  },
  labelText: {
    fontFamily: 'CustomFont',
    fontSize: 12,
  },
  checked: {
    backgroundColor: '#007bff',
    color: '#fff',
  },
  headerImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 155.52,
    height: 57.6,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },

  footerImage: {
    position: 'absolute',
    bottom: 20,
    left: 20,
  },
});

const GDPR = ({
  patientRepresentative = '......................................................',
  patientRepresentativePIN = '......................................................',
  patientRepresentativeAge = '................................',
  patientRepresentativeEmail = '................................',
  patientRepresentativePhoneNumber = '................................',
  patientRepresentativeAddressCityBorn = '.......................................',
  patientRepresentativeAddressCity = '.......................................',
  patientRepresentativeAddressStreet = '........................................',
  patientRepresentativeAddressStreetNo = '............',
  patientRepresentativeAddressStreetBlock = '.............',
  patientRepresentativeAddressBlockEntrance = '..............',
  patientRepresentativeAddressBlockApartment = '................',
  patientRepresentativeAddressCounty = '......................',
  patientRepresentativeSN = '.........................',
  patientRepresentativeSNNo = '...........................',
  patientRepresentativeDocumentIssuedBy = '.......................',
  patientRepresentativeDocumentIssueDate = '..........................',
  patientRepresentativeDocumentType = 'CI/pașaport',
}) => {
  const documentType =
    patientRepresentativeDocumentType ===
    patientRepresentativeDocumentTypeFields[0].value
      ? patientRepresentativeDocumentTypeFields[0].name
      : patientRepresentativeDocumentType ===
        patientRepresentativeDocumentTypeFields[1].value
      ? patientRepresentativeDocumentTypeFields[1].name
      : patientRepresentativeDocumentType;

  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <Image
          src={blondieLogo}
          style={[styles.header, styles.headerImage]}
          fixed
        />

        <View style={styles.title}>
          <Text>{`Declarație privind prelucrarea datelor cu caracter personal`}</Text>
        </View>
        <View style={styles.text}>
          <Text style={styles.sectionText}>
            Subsemnatul/Subsemnata, {patientRepresentative}, având CNP{' '}
            {patientRepresentativePIN}, adresa de e-mail{' '}
            {patientRepresentativeEmail}, telefon{' '}
            {patientRepresentativePhoneNumber}, născut(ă) la data de{' '}
            {patientRepresentativeAge}, în localitatea{' '}
            {patientRepresentativeAddressCityBorn}, domiciliat(ă) în (sat,
            comuna, oraș, mun.) {patientRepresentativeAddressCity} str.{' '}
            {patientRepresentativeAddressStreet}, nr.{' '}
            {patientRepresentativeAddressStreetNo}, bloc.{' '}
            {patientRepresentativeAddressStreetBlock}, sc.{' '}
            {patientRepresentativeAddressBlockEntrance}, ap.{' '}
            {patientRepresentativeAddressBlockApartment}, jud.{' '}
            {patientRepresentativeAddressCounty}, posesor al {documentType}.
            seria {patientRepresentativeSN}, nr. {patientRepresentativeSNNo},
            eliberată de {patientRepresentativeDocumentIssuedBy}, la data de{' '}
            {patientRepresentativeDocumentIssueDate}, prin prezenta îmi exprim
            acordul cu privire la utilizarea și prelucrarea datelor cu caracter
            personal, conform Regulamentul (UE) 2016/679 al Parlamentului
            European și al Consiliului din 27 aprilie 2016 privind protecția
            persoanelor fizice în ceea ce privește prelucrarea datelor cu
            caracter personal și privind libera circulație a acestor date de
            către Agenția Națională de Administrare Fiscală.
          </Text>
        </View>
        <View style={styles.container}>
          <Text style={styles.data}>
            Data: {moment(new Date().toLocaleDateString()).format('DD/MM/YYYY')}
          </Text>
          <Text style={styles.sectionText}>Semnătura:</Text>
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
        <View style={[styles.footer, styles.footerImage]} fixed>
          <Text style={styles.sectionText}>Document generat cu iCRM.ro</Text>
        </View>
      </Page>
    </Document>
  );
};

export default GDPR;
