import React from 'react';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Svg,
  Path,
  Image,
} from '@react-pdf/renderer';

import blondieLogo from '../../assets/images/blondie-logo.png';
import icaminLogo from '../../assets/images/icamin114x114.png';
import { patientRequirement } from '../../constants/index.js';
import fontRegular from '../../assets/fonts/AbhayaLibre-Regular.ttf';
import fontBold from '../../assets/fonts/AbhayaLibre-Regular.ttf';
import moment from 'moment/moment';

Font.register({
  family: 'CustomFont',
  format: 'truetype',

  src: fontRegular,
});

Font.register({
  family: 'CustomFontBold',
  format: 'truetype',

  src: fontBold,
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 64,
  },
  title: {
    fontSize: 12,
    marginBottom: 32,
    fontWeight: 900,
    textAlign: 'center',
    fontFamily: 'CustomFontBold',
  },
  section: {
    marginBottom: 10,
  },
  sectionTextParagraph: {
    fontSize: 12,
    marginBottom: 5,
    textIndent: 16,
    fontFamily: 'CustomFont',
  },
  sectionText: {
    fontSize: 12,
    marginBottom: 12,
    fontFamily: 'CustomFont',
  },
  checkboxLabel: {
    fontSize: 12,
    marginLeft: 10,
    fontFamily: 'CustomFont',
  },

  data: {
    fontSize: 12,
    fontFamily: 'CustomFont',
  },
  checkboxContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  checkbox: {
    width: 12,
    height: 12,
    marginRight: 5,
    justifyContent: 'center',
  },
  labelText: {
    fontFamily: 'CustomFont',
    fontSize: 12,
  },
  checked: {
    backgroundColor: '#007bff',
    color: '#fff',
  },
  headerImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 155.52,
    height: 57.6,
  },

  footerImage: {
    position: 'absolute',
    bottom: 20,
    left: 20,
  },
});

const Checkbox = ({ checked }) => {
  return (
    <View style={styles.checkbox}>
      <Svg width={12} height={12} viewBox='0 0 12 12'>
        <Path d='M1 1H11V11H1z' stroke='black' strokeWidth='1' fill='none' />
        {checked && (
          <Path
            d='M1.5 6L4 8.5L10.5 2'
            stroke='black'
            strokeWidth='1'
            fill='none'
            strokeLinecap='round'
          />
        )}
      </Svg>
    </View>
  );
};

const FinalRequest = ({
  patientRepresentative = '................................................................',
  patientFirstAndLastName,
  phoneNumber = '................................................................',
  email = '................................................................',
  patientAge = '......................',
  diagnoses = '........................................................................',
}) => {
  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <Image src={blondieLogo} style={styles.headerImage} />

        <Text style={styles.title}>CERERE</Text>
        <View style={styles.section}>
          <Text style={styles.sectionTextParagraph}>
            Subsemnata/ul {patientRepresentative} având numărul de telefon{' '}
            {phoneNumber} și adresa de e-mail {email}, solicit sprijinul pentru
            copilul {patientFirstAndLastName}, cu vârsta de {patientAge}, care a
            fost diagnosticat cu următoarele:
          </Text>
          {Array.isArray(diagnoses) &&
            diagnoses?.length > 0 &&
            diagnoses?.map((diagnosis, index) => (
              <Text key={index} style={styles.sectionText}>
                {index + 1}.{diagnosis.patientDiagnosis}
              </Text>
            ))}
          {/* <Text style={styles.sectionText}>1.{diagnoses}</Text>
          <Text style={styles.sectionText}>
            2.........................................................................
          </Text>
          <Text style={styles.sectionText}>
            3.........................................................................
          </Text>
          <Text style={styles.sectionText}>
            4.........................................................................
          </Text> */}
        </View>
        <View style={styles.section}>
          <Text style={styles.data}>
            Data: {moment(new Date().toLocaleDateString()).format('DD/MM/YYYY')}
          </Text>
        </View>
        <View style={styles.footerImage}>
          <Text style={styles.sectionText}>Document generat cu iCRM.ro</Text>
        </View>
      </Page>
    </Document>
  );
};

export default FinalRequest;
