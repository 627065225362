import { Link, useNavigate } from 'react-router-dom';

// @mui material components
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';

// Authentication layout components
import CoverLayout from 'layouts/authentication/components/CoverLayout';

// Images
import bgImage from 'assets/images/bg-sign-up-cover.jpeg';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { useLoginMutation, useSignupMutation } from '../../../redux/api/user';
import * as Yup from 'yup';
import Input from '../../../components/input';
import React from 'react';
import BasicLayout from '../components/BasicLayout';
import Spinner from '../../../components/spinner';

// import './style.scss';

const schema = Yup.object({
  email: Yup.string().email('Invalid Email').required('Email is required'),
  phoneNumber: Yup.string().matches(
    /^(07[1-9])(\d{7})$/,
    'Phone number is not valid',
  ),
  password: Yup.string()
    .required('Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*[\]{}()?"\\,><':;|_~`=+-])[a-zA-Z\d!@#$%^&*[\]{}()?"\\,><':;|_~`=+-]{8,99}$/,
      'Must contain at least 8 Characters, 1 Uppercase, 1 Lowercase, 1 Special Character, and 1 Number',
    ),
  confirmPassword: Yup.string()
    .required('Confirm password is required')
    .oneOf([Yup.ref('password')], 'Passwords do not match'),
});

const validateEmail = () => {
  return Yup.string().email('Invalid Email');
};

const validatePhone = () => {
  return Yup.string().matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*[\]{}()?"\\,><':;|_~`=+-])[a-zA-Z\d!@#$%^&*[\]{}()?"\\,><':;|_~`=+-]{8,99}$/,
  );
};

function Cover() {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    trigger,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();

  const [triggerSignup, { isLoading }] = useSignupMutation();
  const onSubmit = async (data) => {
    const userData = {
      email: data.email,
      phone: data.phoneNumber,
      password: data.password,
    };
    try {
      await triggerSignup(userData).unwrap();
      navigate('/login');
    } catch (e) {
      console.error('err', e);
    }
  };
  return (
    <>
      {isLoading && <Spinner />}
      <BasicLayout style={{ marginTop: '3rem', marginBottom: '3rem' }}>
        <Card>
          <MDBox
            variant='gradient'
            bgColor='info'
            borderRadius='lg'
            coloredShadow='success'
            mx={2}
            mt={-3}
            p={3}
            mb={1}
            textAlign='center'
          >
            <MDTypography variant='h4' fontWeight='medium' color='white' mt={1}>
              Join us today
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox
              component='form'
              role='form'
              onSubmit={handleSubmit(onSubmit)}
            >
              <MDBox mb={2}>
                <Input
                  placeholder='Email'
                  type='email'
                  name='email'
                  label='Email'
                  error={errors.email}
                  {...register('email', {
                    onChange: () => {
                      (isValid || errors.email) && trigger('email');
                    },
                  })}
                />
              </MDBox>
              <MDBox mb={2}>
                <Input
                  placeholder='Phone Number'
                  type='phoneNumber'
                  name='phoneNumber'
                  label='Phone Number'
                  error={errors.phoneNumber}
                  {...register('phoneNumber', {
                    onChange: () => {
                      (isValid || errors.phoneNumber) && trigger('phoneNumber');
                    },
                  })}
                />
              </MDBox>
              <MDBox mb={2}>
                <Input
                  placeholder='Password'
                  type='password'
                  name='password'
                  label='Password'
                  error={errors.password}
                  {...register('password', {
                    onChange: () => {
                      (isValid || errors.password) && trigger('password');
                    },
                  })}
                />
              </MDBox>
              <MDBox mb={2}>
                <Input
                  placeholder='Confirm Password'
                  type='password'
                  name='confirmPassword'
                  label='Confirm Password'
                  error={errors.confirmPassword}
                  {...register('confirmPassword', {
                    onChange: () => {
                      (isValid || errors.confirmPassword) &&
                        trigger('confirmPassword');
                    },
                  })}
                />
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton
                  variant='gradient'
                  color='info'
                  fullWidth
                  type='submit'
                >
                  sign in
                </MDButton>
              </MDBox>
              <MDBox mt={3} mb={1} textAlign='center'>
                <MDTypography variant='button' color='text'>
                  Already have an account?{' '}
                  <MDTypography
                    component={Link}
                    to='/login'
                    variant='button'
                    color='info'
                    fontWeight='medium'
                    textGradient
                  >
                    Sign In
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </BasicLayout>
    </>
  );
}

export default Cover;
