import api from './api.js';
import { patientRequirement, stepFields } from '../../constants';
import { capitalize } from '../../util/form';
import { formatForDatePicker } from '../../util/date';

const transformCaseData = (data) => {
  let formattedFormData = {};
  Object.keys(data).forEach((key) => {
    if (
      [
        'patientAge',
        'patientRepresentativeDocumentIssueDate',
        'hospitalizationDate',
        'clinicAppointment',
        'patientRepresentativeAge',
      ].some((substring) => key.includes(substring))
    ) {
      data[key] = formatForDatePicker(data[key]);
    }
    if (key === 'attachments') {
      const attachmentsArray = [];
      data[key].forEach((attachment) => {
        attachmentsArray.push({ name: attachment, isDirty: false });
      });
      if (
        data?.patientRequirement === patientRequirement[0].value ||
        data?.patientRequirement === patientRequirement[3].value
      ) {
        formattedFormData['patientMedicalDocuments'] = attachmentsArray;
        // setValue('patientMedicalDocuments', attachmentsArray);
        // setUploadedFiles({ patientMedicalDocuments: attachmentsArray });
      }
      if (data?.patientRequirement === patientRequirement[1].value) {
        formattedFormData['sbpTwoPatientMedicalDocuments'] = attachmentsArray;

        // setValue('sbpTwoPatientMedicalDocuments', attachmentsArray);
        // setUploadedFiles({
        //   sbpTwoPatientMedicalDocuments: attachmentsArray,
        // });
      }
      if (data?.patientRequirement === patientRequirement[2].value) {
        formattedFormData['sbpThreePatientMedicalDocuments'] = attachmentsArray;

        // setValue('sbpThreePatientMedicalDocuments', attachmentsArray);
        // setUploadedFiles({
        //   sbpThreePatientMedicalDocuments: attachmentsArray,
        // });
      }
    } else if (!key.includes('MedicalDocuments')) {
      if (key.toLowerCase() === 'diagnoses' && data[key].length !== 0) {
        formattedFormData['diagnoses'] = data[key];
      } else if (key === 'requestedCase') {
        formattedFormData[key] = data[key];
      } else if (
        (stepFields[1][1].includes(key) ||
          stepFields[3][1].includes(key) ||
          stepFields[4][1].includes(key)) &&
        data[key] !== 'null'
      ) {
        formattedFormData[key] = data[key];
        // setValue(key, data[key]);
      } else if (data?.patientRequirement === patientRequirement[1].value) {
        if (
          key === 'medicalTransportNeeds' ||
          key === 'medicalTransportNeedsCustomInput' ||
          key === 'medicalTransportNotes' ||
          key === 'medicalTransportNotesCustomInput'
        ) {
          formattedFormData[`sbpTwoMedicalAir${capitalize(key).slice(7)}`] =
            data[key];
          // setValue(`sbpTwoMedicalAir${capitalize(key).slice(7)}`, data[key]);
        } else if (capitalize(key).includes('TransportAttendant')) {
          formattedFormData[`sbpTwoAir${capitalize(key)}`] = data[key];
          // setValue(`sbpTwoAir${capitalize(key)}`, data[key]);
        } else if (key === 'departureAndDestination') {
          formattedFormData['sbpTwoDepartureAndDestinationAerial'] = data[key];
        } else {
          formattedFormData[`sbpTwo${capitalize(key)}`] = data[key];
          // setValue(`sbpTwo${capitalize(key)}`, data[key]);
        }
      } else if (data?.patientRequirement === patientRequirement[2].value) {
        if (
          key === 'medicalTransportNeeds' ||
          key === 'medicalTransportNeedsCustomInput' ||
          key === 'medicalTransportNotes' ||
          key === 'medicalTransportNotesCustomInput'
        ) {
          formattedFormData[`sbpThreeMedicalLand${capitalize(key).slice(7)}`] =
            data[key];

          // setValue(`sbpThreeMedicalLand${capitalize(key).slice(7)}`, data[key]);
        } else if (capitalize(key).includes('TransportAttendant')) {
          formattedFormData[`sbpThreeLand${capitalize(key)}`] = data[key];

          // setValue(`sbpThreeLand${capitalize(key)}`, data[key]);
        } else if (key === 'departureAndDestination') {
          formattedFormData['sbpThreeDepartureAndDestinationLand'] = data[key];
        } else {
          formattedFormData[`sbpThree${capitalize(key)}`] = data[key];

          // setValue(`sbpThree${capitalize(key)}`, data[key]);
        }
      }
    }
  });

  return { data, formattedFormData };
};

const caseApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCases: builder.query({
      query: () => '/cases',
      providesTags: ['cases'],
      keepUnusedDataFor: 0,

      transformResponse: (res) => res.data,
    }),
    getCase: builder.query({
      query: (id) => `cases/${id}`,
      transformResponse: (res) => transformCaseData(res.data),
      providesTags: ['cases'],

      // providesTags: (res, error, id) => [{ type: 'case', id }],
      keepUnusedDataFor: 0,
      provides: (data, { keepUnusedDataFor }) => {
        // Specify the keepUnusedDataFor option here
        return { keepUnusedDataFor: 0 }; // Time in milliseconds
      },
    }),

    getContactCases: builder.query({
      query: () => 'cases/contact',
      providesTags: ['cases'],
      transformResponse: (res) => res.data,
    }),

    addCase: builder.mutation({
      query: (body) => {
        const formData = new FormData();

        const medicalDocumentsKeys = Object.keys(body).filter((key) =>
          key.includes('MedicalDocuments'),
        );

        if (medicalDocumentsKeys.length > 0) {
          medicalDocumentsKeys.forEach((key) => {
            const files = body[key];
            files.forEach((file) => {
              formData.append('files', file);
            });
          });
        }
        Object.entries(body).forEach(([key, value]) => {
          if (!key.includes('MedicalDocuments')) {
            if (
              key.toLowerCase().includes('diagnoses')
              //  Array.isArray(value)
            ) {
              formData.append('diagnoses', JSON.stringify(value));
            } else {
              formData.append(key, value);
            }
          }
        });
        // for (let [key, value] of formData.entries()) {
        //   console.log('fff formData', key, value);
        // }
        return {
          url: '/cases',
          method: 'POST',
          body: formData,
          responseType: 'json',
        };
      },
      invalidatesTags: ['cases'],
      transformResponse: (res) => res.data,
    }),
    addCaseAttachments: builder.mutation({
      query: ({ attachments, id }) => {
        const formData = new FormData();
        Object.values(attachments).forEach((file) => {
          formData.append('files', file);
        });

        // for (let [key, value] of formData.entries()) {
        //   console.log('fff formData', key, value);
        // }

        return {
          url: `/cases/${id}/upload`,
          method: 'POST',
          body: formData,
          responseType: 'json',
        };
      },
      invalidatesTags: ['cases'],

      // invalidatesTags: ({ id }) => [{ type: 'case', id }, 'cases'],
    }),
    updateCase: builder.mutation({
      query: ({ body, id }) => {
        const formData = new FormData();
        const medicalDocuments = [];

        if (
          body?.patientRequirement === patientRequirement[0].value ||
          body?.patientRequirement === patientRequirement[3].value
        ) {
          medicalDocuments.push(body.patientMedicalDocuments);
        }
        if (body?.patientRequirement === patientRequirement[1].value) {
          medicalDocuments.push(body.sbpTwoPatientMedicalDocuments);
        }
        if (body?.patientRequirement === patientRequirement[2].value) {
          medicalDocuments.push(body.sbpThreePatientMedicalDocuments);
        }

        if (medicalDocuments.length > 0) {
          medicalDocuments.flat().forEach((file) => {
            if (file?.isDirty) {
              formData.append('files', file);
            }
          });
        }

        Object.entries(body).forEach(([key, value]) => {
          if (!key.includes('MedicalDocuments')) {
            if (
              key.toLowerCase().includes('diagnoses')
              //  Array.isArray(value)
            ) {
              formData.append('diagnoses', JSON.stringify(value));
            } else if (Array.isArray(value)) {
              formData.append(key, JSON.stringify(value));
            } else {
              formData.append(key, value);
            }
          }
        });
        // for (let [key, value] of formData.entries()) {
        //   console.log('fff formData', key, value);
        // }
        return {
          url: `/cases/${id}/`,
          method: 'PATCH',
          body: formData,
          responseType: 'json',
        };
      },
      invalidatesTags: ['cases'],

      // keepUnusedDataFor: 0,
      // invalidatesTags: (res, error, id) => [{ type: 'case', id }, 'cases'],
    }),
    deleteCaseAttachment: builder.mutation({
      query: ({ attachment, id }) => {
        return {
          url: `/cases/${id}/delete?fileName=${encodeURIComponent(attachment)}`,
          method: 'DELETE',
          // responseType: 'json',
        };
      },
      invalidatesTags: ['cases'],

      // invalidatesTags: ({ id }) => [{ type: 'case', id }],
    }),
  }),
});

export default caseApi;

export const {
  useAddCaseMutation,
  useGetCaseQuery,
  useGetCasesQuery,
  useLazyGetCasesQuery,
  useGetContactCasesQuery,
  useLazyGetAttachmentQuery,
  useAddCaseAttachmentsMutation,
  useDeleteCaseAttachmentMutation,
  useUpdateCaseMutation,
} = caseApi;
