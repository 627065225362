import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL } from '../../constants';

const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://blondie-api.icrm.ro/api',
    // baseUrl: 'http://localhost:8000/api',
    credentials: 'include',
  }),
  keepUnusedDataFor: 0,
  // keepUnusedDataFor: import.meta.env.VITE_CACHE_RESET_TIME ?? 300,
  tagTypes: ['user'],
  endpoints: () => ({}),
});

export default api;
