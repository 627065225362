const user = {
  isLoggedIn: false,
};

const updateUserData = (state, action) => ({
  ...state,
  isLoggedIn: action.payload.isLoggedIn,
});
const actionTypeCases = {
  UPDATE_USER_DATA: updateUserData,
};

const reducer = (state = { ...user }, action) => {
  try {
    return actionTypeCases[action.type].call(null, state, action);
  } catch (ignore) {
    return state;
  }
};

export default reducer;
