import React from 'react';
import { useFieldArray } from 'react-hook-form';
import Input from '../input/index.jsx';
import RadioButtonsList from '../radio-buttons-list/index.jsx';
import {
  patientRelationship,
  patientRepresentativeDocumentTypeFields,
  patientRequirement,
} from '../../constants/index.js';
import MDButton from 'components/MDButton';
import Grid from '@mui/material/Grid';

const CaseStepOne = ({
  register,
  errors,
  trigger,
  setValue,
  setDirtyFields,
  watch,
  control,
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'diagnoses',
  });
  const radioGroupPatientRelationshipValue = watch('patientRelationship');
  const radioGroupPatientRequirementValue = watch('patientRequirement');
  const radioGroupPatientRepresentativeDocumentTypeValue = watch(
    'patientRepresentativeDocumentType',
  );

  const customInputValue = watch('patientRelationshipCustomInput', '');

  // console.log(
  //   'radioGroupPatientRequirementValue',
  //   radioGroupPatientRequirementValue,
  // );
  // console.log('radioGroupValue sttt', radioGroupValue);
  // console.log('customInputValue', customInputValue);

  const onRadioChange = (e, something) => {
    setDirtyFields((prevState) => ({
      ...prevState,
      [e.target.name]: true,
    }));
    trigger(e.target.name);
    setValue(e.target.name, e.target.value);
    if (e.target.name === 'patientRelationshipCustomInput') {
      setValue('patientRelationship', 'other');
      setDirtyFields((prevState) => ({
        ...prevState,
        ['patientRelationship']: true,
      }));
    }
  };

  const onInputChange = (e) => {
    trigger(e.target.name);
    setDirtyFields((prevState) => ({
      ...prevState,
      [e.target.name]: true,
    }));
  };

  return (
    <>
      <Input
        placeholder='Your answer'
        type='text'
        name='firstAndLastName'
        label='Nume și prenume solicitant*'
        error={errors.firstAndLastName}
        {...register('firstAndLastName', {
          onChange: (e) => onInputChange(e),
        })}
      />
      <Input
        placeholder='Your answer'
        type='email'
        name='email'
        label='Adresă de e-mail solicitant*'
        error={errors.email}
        {...register('email', {
          onChange: (e) => onInputChange(e),
        })}
      />
      <Input
        placeholder='Your answer'
        type='text'
        name='phoneNumber'
        label='Număr de telefon solicitant*'
        error={errors.phoneNumber}
        {...register('phoneNumber', {
          onChange: (e) => onInputChange(e),
        })}
      />
      <RadioButtonsList
        register={register}
        radioGroupValue={radioGroupPatientRelationshipValue}
        customInputValue={customInputValue}
        onChange={onRadioChange}
        error={
          errors.patientRelationship
            ? errors.patientRelationship.message
            : errors.patientRelationshipCustomInput &&
              radioGroupPatientRelationshipValue === 'other'
            ? errors.patientRelationshipCustomInput.message
            : null
        }
        name='patientRelationship'
        className='add-case__patient-relationship__section'
        title='Calitate față de pacient*'
        list={patientRelationship}
      />
      <RadioButtonsList
        register={register}
        name='patientRequirement'
        radioGroupValue={radioGroupPatientRequirementValue}
        onChange={onRadioChange}
        error={errors.patientRequirement && errors.patientRequirement.message}
        className='add-case__patient-relationship__section'
        title='Ce solicitați?*'
        list={patientRequirement}
      />
      <Input
        placeholder='Your answer'
        type='text'
        name='patientRepresentative'
        label='Nume și prenume reprezentant pacient*'
        error={errors.patientRepresentative}
        {...register('patientRepresentative', {
          onChange: (e) => onInputChange(e),
        })}
      />
      <Input
        placeholder='Your answer'
        type='text'
        name='patientRepresentativeEmail'
        label='Adresă de e-mail reprezentant pacient'
        error={errors.patientRepresentativeEmail}
        {...register('patientRepresentativeEmail', {
          onChange: (e) => onInputChange(e),
        })}
      />
      <Input
        placeholder='Your answer'
        type='text'
        name='patientRepresentativePhoneNumber'
        label='Număr de telefon reprezentant pacient'
        error={errors.patientRepresentativePhoneNumber}
        {...register('patientRepresentativePhoneNumber', {
          onChange: (e) => onInputChange(e),
        })}
      />

      <h3>Adresă reprezentant pacient</h3>
      <Grid
        container
        spacing={4}
        className='address__section'
        style={{ width: '100%', gap: '1rem' }}
      >
        <Grid item xs={12} md={6} lg={3} style={{ padding: 0 }}>
          <Input
            placeholder='Your answer'
            type='text'
            autocomplete='address-line1'
            name='patientRepresentativeAddressStreet'
            label='Strada'
            {...register('patientRepresentativeAddressStreet', {
              onChange: (e) => onInputChange(e),
            })}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={2} style={{ padding: 0 }}>
          <Input
            placeholder='Your answer'
            type='text'
            name='patientRepresentativeAddressStreetNo'
            label='Nr.'
            {...register('patientRepresentativeAddressStreetNo', {
              onChange: (e) => onInputChange(e),
            })}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3} style={{ padding: 0 }}>
          <Input
            placeholder='Your answer'
            type='text'
            autocomplete='address-level1'
            name='patientRepresentativeAddressCounty'
            label='Județ'
            {...register('patientRepresentativeAddressCounty', {
              onChange: (e) => onInputChange(e),
            })}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3} style={{ padding: 0 }}>
          <Input
            placeholder='Your answer'
            type='text'
            autocomplete='address-level2'
            name='patientRepresentativeAddressCity'
            label='Localitate'
            {...register('patientRepresentativeAddressCity', {
              onChange: (e) => onInputChange(e),
            })}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={2} style={{ padding: 0 }}>
          <Input
            placeholder='Your answer'
            type='text'
            name='patientRepresentativeAddressCitySector'
            label='Sector'
            {...register('patientRepresentativeAddressCitySector', {
              onChange: (e) => onInputChange(e),
            })}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={2} style={{ padding: 0 }}>
          <Input
            placeholder='Your answer'
            type='text'
            name='patientRepresentativeAddressStreetBlock'
            label='Bl.'
            {...register('patientRepresentativeAddressStreetBlock', {
              onChange: (e) => onInputChange(e),
            })}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={2} style={{ padding: 0 }}>
          <Input
            placeholder='Your answer'
            type='text'
            name='patientRepresentativeAddressBlockEntrance'
            label='Sc.'
            {...register('patientRepresentativeAddressBlockEntrance', {
              onChange: (e) => onInputChange(e),
            })}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={2} style={{ padding: 0 }}>
          <Input
            placeholder='Your answer'
            type='text'
            name='patientRepresentativeAddressBlockFloor'
            label='Et.'
            {...register('patientRepresentativeAddressBlockFloor', {
              onChange: (e) => onInputChange(e),
            })}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={2} style={{ padding: 0 }}>
          <Input
            placeholder='Your answer'
            type='text'
            name='patientRepresentativeAddressBlockApartment'
            label='Ap.'
            {...register('patientRepresentativeAddressBlockApartment', {
              onChange: (e) => onInputChange(e),
            })}
          />
        </Grid>
      </Grid>

      <Input
        type='text'
        name='patientRepresentativePIN'
        label='CNP reprezentant pacient'
        {...register('patientRepresentativePIN', {
          onChange: (e) => onInputChange(e),
        })}
      />

      <Input
        type='date'
        name='patientRepresentativeAge'
        label='Data nașterii reprezentant pacient'
        {...register('patientRepresentativeAge', {
          onChange: (e) => onInputChange(e),
        })}
      />

      <Input
        type='text'
        name='patientRepresentativeAddressCityBorn'
        label='Reprezentant pacient născut în localitatea'
        {...register('patientRepresentativeAddressCityBorn', {
          onChange: (e) => onInputChange(e),
        })}
      />

      <RadioButtonsList
        register={register}
        radioGroupValue={radioGroupPatientRepresentativeDocumentTypeValue}
        onChange={onRadioChange}
        name='patientRepresentativeDocumentType'
        // className='add-case__patient-relationship__section'
        title='Tip document reprezentant pacient'
        list={patientRepresentativeDocumentTypeFields}
      />

      <Input
        type='text'
        name='patientRepresentativeSN'
        label='Seria C.I./pașaport reprezentant pacient'
        {...register('patientRepresentativeSN', {
          onChange: (e) => onInputChange(e),
        })}
      />

      <Input
        type='text'
        name='patientRepresentativeSNNo'
        label='Nr. C.I./pașaport reprezentant pacient'
        {...register('patientRepresentativeSNNo', {
          onChange: (e) => onInputChange(e),
        })}
      />

      <Input
        type='text'
        name='patientRepresentativeDocumentIssuedBy'
        label='Eliberat de (reprezentant pacient)'
        {...register('patientRepresentativeDocumentIssuedBy', {
          onChange: (e) => onInputChange(e),
        })}
      />

      <Input
        type='date'
        name='patientRepresentativeDocumentIssueDate'
        label='La data (reprezentant pacient)'
        {...register('patientRepresentativeDocumentIssueDate', {
          onChange: (e) => onInputChange(e),
        })}
      />

      <Input
        // placeholder='Nume si prenume'
        type='text'
        name='patientFirstAndLastName'
        label='Nume și prenume pacient*'
        error={errors.patientFirstAndLastName}
        {...register('patientFirstAndLastName', {
          onChange: (e) => onInputChange(e),
        })}
      />

      <Input
        type='text'
        name='patientPIN'
        label='CNP pacient'
        {...register('patientPIN', {
          onChange: (e) => onInputChange(e),
        })}
      />

      <Input
        type='date'
        placeholder='dd-mm-yyyy'
        name='patientAge'
        label='Data nașterii pacient*'
        error={errors.patientAge}
        {...register('patientAge', {
          onChange: (e) => onInputChange(e),
        })}
      />
      {fields.map((diagnosis, index) => (
        <div key={diagnosis.id}>
          <Input
            type='text'
            name={`diagnoses.${index}.patientDiagnosis`}
            {...(index === 0 && {
              label:
                'Diagosticul actual al pacientului pe scurt confirmat de către un medic/spital*',
            })}
            {...register(`diagnoses.${index}.patientDiagnosis`, {
              onChange: () => {
                trigger(`diagnoses.${index}.patientDiagnosis`);
                setDirtyFields((prevState) => ({
                  ...prevState,
                  ['diagnoses']: true,
                }));
              },
            })}
            error={errors?.diagnoses?.[index]?.patientDiagnosis}
          />

          {index !== 0 && (
            <MDButton type='button' onClick={() => remove(index)}>
              Elimină diagnostic
            </MDButton>
          )}
        </div>
      ))}
      <MDButton type='button' onClick={() => append({ patientDiagnosis: '' })}>
        Adaugă diagnostic
      </MDButton>
      {/*<Button type='submit' style={STYLE.PINK}>*/}
      {/*  Submit*/}
      {/*</Button>*/}
    </>
  );
};

export default CaseStepOne;
// {...register('firstAndLastName', {
//     onChange: () => {
//         (isValid || errors.firstAndLastName) && trigger('firstAndLastName');
//     },
// })}
