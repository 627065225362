import React, { useEffect } from 'react';
import { useFieldArray } from 'react-hook-form';
import Input from '../../input/index.jsx';
import MDButton from '../../MDButton';

import InputFile from '../../input-file/index.jsx';
import {
  sbpThreeLandTransportAttendantFields,
  sbpThreeMedicalLandTransportNotesFields,
  sbpThreePatientAttendantTravelDocumentFields,
  sbpThreePatientLocationFields,
  sbpThreePatientTravelDocumentFields,
  sbpThreeTransportToClinicFields,
  sbpThreePatientTreatmentInRomaniaFields,
  sbpThreeClinicAcceptanceFields,
} from '../../../constants/index.js';
import RadioButtonsList from '../../radio-buttons-list/index.jsx';

const CaseSubstepThree = ({
  register,
  errors,
  trigger,
  setValue,
  setDirtyFields,
  watch,
  substep,
  handleFileUpload,
  handleRemoveFile,
  uploadedFiles,
  // control,
}) => {
  // const { fields, append, remove } = useFieldArray({
  //   control,
  //   name: 'sbpThreeDiagnoses',
  // });

  const sbpThreePatientTreatmentInRomania = watch(
    'sbpThreePatientTreatmentInRomania',
  );
  const sbpThreeClinicAcceptance = watch('sbpThreeClinicAcceptance');
  const sbpThreeLandTransportAttendant = watch(
    'sbpThreeLandTransportAttendant',
  );
  const sbpThreePatientTravelDocument = watch('sbpThreePatientTravelDocument');
  const sbpThreePatientAttendantTravelDocument = watch(
    'sbpThreePatientAttendantTravelDocument',
  );
  const sbpThreeTransportToClinic = watch('sbpThreeTransportToClinic');
  const sbpThreeMedicalLandTransportNeeds = watch(
    'sbpThreeMedicalLandTransportNeeds',
  );
  const sbpThreeMedicalLandTransportNotes = watch(
    'sbpThreeMedicalLandTransportNotes',
  );
  const sbpThreePatientLocation = watch('sbpThreePatientLocation');

  const patientLocation = watch('patientLocation');
  const patientLocationCustomInput = watch('patientLocationCustomInput', '');
  const sbpThreeTransportToClinicCustomInput = watch(
    'sbpThreeTransportToClinicCustomInput',
    '',
  );
  const sbpThreeLandTransportAttendantCustomInput = watch(
    'sbpThreeLandTransportAttendantCustomInput',
    '',
  );
  const sbpThreeMedicalLandTransportNeedsCustomInput = watch(
    'sbpThreeMedicalLandTransportNeedsCustomInput',
    '',
  );
  const sbpThreeMedicalLandTransportNotesCustomInput = watch(
    'sbpThreeMedicalLandTransportNotesCustomInput',
    '',
  );
  const sbpThreePatientLocationCustomInput = watch(
    'sbpThreePatientLocationCustomInput',
    '',
  );

  const onChange = (e) => {
    setDirtyFields((prevState) => ({
      ...prevState,
      [e.target.name]: true,
    }));
    setValue(e.target.name, e.target.value);
    if (e.target.name === 'patientLocationCustomInput') {
      // console.log('in useffect');
      setValue('patientLocation', 'other');
      setDirtyFields((prevState) => ({
        ...prevState,
        ['patientLocation']: true,
      }));
    }
    if (e.target.name === 'sbpThreeLandTransportAttendantCustomInput') {
      // console.log('in useffect');
      setValue('sbpThreeLandTransportAttendant', 'other');
      setDirtyFields((prevState) => ({
        ...prevState,
        ['sbpThreeLandTransportAttendant']: true,
      }));
    }
    if (e.target.name === 'sbpThreeTransportToClinicCustomInput') {
      // console.log('in useffect');
      setValue('sbpThreeTransportToClinic', 'other');
      setDirtyFields((prevState) => ({
        ...prevState,
        ['sbpThreeTransportToClinic']: true,
      }));
    }
    if (e.target.name === 'sbpThreeMedicalLandTransportNeedsCustomInput') {
      // console.log('in useffect');
      setValue('sbpThreeMedicalLandTransportNeeds', 'other');
      setDirtyFields((prevState) => ({
        ...prevState,
        ['sbpThreeMedicalLandTransportNeeds']: true,
      }));
    }
    if (e.target.name === 'sbpThreeMedicalLandTransportNotesCustomInput') {
      // console.log('in useffect');
      setValue('sbpThreeMedicalLandTransportNotes', 'other');
      setDirtyFields((prevState) => ({
        ...prevState,
        ['sbpThreeMedicalLandTransportNotes']: true,
      }));
    }
    if (e.target.name === 'sbpThreePatientLocationCustomInput') {
      // console.log('in useffect');
      setValue('sbpThreePatientLocation', 'other');
      setDirtyFields((prevState) => ({
        ...prevState,
        ['sbpThreePatientLocation']: true,
      }));
    }
  };

  const onInputChange = (e) => {
    trigger(e.target.name);
    setDirtyFields((prevState) => ({
      ...prevState,
      [e.target.name]: true,
    }));
    // setValue('radioGroup-customInput', value);
  };

  return (
    <>
      <InputFile
        id='sbpThreePatientMedicalDocuments'
        name='sbpThreePatientMedicalDocuments'
        label='Vă rugăm să atașați documentele medicale:'
        optionalText='Ex: scrisoare medicală, analize de sânge, bilet de externare, ecografii, CT, RMN, etc.'
        handleUploadedFile={(files) => {
          handleFileUpload('sbpThreePatientMedicalDocuments', files);
        }}
        handleRemoveFile={handleRemoveFile}
        onChange={(event) => {
          // const handleChange = (event) => {
          handleFileUpload(
            'sbpThreePatientMedicalDocuments',
            event.target.files,
          );
          // };
          // trigger('sbpThreePatientMedicalDocuments');
        }}
        uploadedFiles={uploadedFiles}
        error={errors.sbpThreePatientMedicalDocuments}
        multiple={true}
        message='Files'
      />

      <Input
        // placeholder='Nume si prenume'
        type='text'
        name='sbpThreePatientHistory'
        label='Istoricul pacientului pe scurt'
        optionalText='Internări precedente, locul în care pacientul se află în prezent'
        error={errors.sbpThreePatientHistory}
        {...register('sbpThreePatientHistory', {
          onChange: (e) => onInputChange(e),
        })}
      />

      <RadioButtonsList
        register={register}
        radioGroupValue={sbpThreePatientTreatmentInRomania}
        onChange={onChange}
        error={
          errors.sbpThreePatientTreatmentInRomania &&
          errors.sbpThreePatientTreatmentInRomania.message
        }
        name='sbpThreePatientTreatmentInRomania'
        className='add-case__patient-relationship__section'
        title='Din informațiile pe care le dețineți la momentul actual, tratamentul se poate efectua pe teritoriul României?'
        list={sbpThreePatientTreatmentInRomaniaFields}
      />

      <RadioButtonsList
        register={register}
        radioGroupValue={sbpThreeClinicAcceptance}
        onChange={onChange}
        error={
          errors.sbpThreeClinicAcceptance &&
          errors.sbpThreeClinicAcceptance.message
        }
        name='sbpThreeClinicAcceptance'
        className='add-case__patient-relationship__section'
        title='Aveți acceptul de primire al unei clinici din străinătate?'
        list={sbpThreeClinicAcceptanceFields}
      />

      <Input
        // placeholder='Nume si prenume'
        type='date'
        name='sbpThreeClinicAppointment'
        label='Data la care sunteți așteptați de către clinica din străinătate'
        error={errors.sbpThreeClinicAppointment}
        {...register('sbpThreeClinicAppointment', {
          onChange: (e) => onInputChange(e),
        })}
      />

      <Input
        // placeholder='Nume si prenume'
        type='text'
        name='sbpThreeClinicContact'
        label='Aveți un contact al unui reprezentat al clinicii din străinătate?'
        error={errors.sbpThreeClinicContact}
        {...register('sbpThreeClinicContact', {
          onChange: (e) => onInputChange(e),
        })}
      />

      <Input
        // placeholder='Nume si prenume'
        type='text'
        name='sbpThreeDepartureAndDestinationLand'
        label='Locul de plecare și locul de destinație al transportului terestru medical'
        error={errors.sbpThreeDepartureAndDestinationLand}
        {...register('sbpThreeDepartureAndDestinationLand', {
          onChange: (e) => onInputChange(e),
        })}
      />

      <RadioButtonsList
        register={register}
        radioGroupValue={sbpThreeLandTransportAttendant}
        customInputValue={sbpThreeLandTransportAttendantCustomInput}
        onChange={onChange}
        error={
          (errors.sbpThreeLandTransportAttendant &&
            errors.sbpThreeLandTransportAttendant.message) ||
          (sbpThreeLandTransportAttendant === 'other' &&
            sbpThreeLandTransportAttendantCustomInput === '' &&
            'Required')
        }
        name='sbpThreeLandTransportAttendant'
        className='add-case__location__section'
        title='Cine va însoți pacientul pe durata transportului terestru medical?'
        list={sbpThreeLandTransportAttendantFields}
      />

      <RadioButtonsList
        register={register}
        radioGroupValue={sbpThreePatientTravelDocument}
        onChange={onChange}
        error={
          errors.sbpThreePatientTravelDocument &&
          errors.sbpThreePatientTravelDocument.message
        }
        name='sbpThreePatientTravelDocument'
        className='add-case__location__section'
        title='Pacientul deține următoarele documente de călătorie'
        list={sbpThreePatientTravelDocumentFields}
      />

      <RadioButtonsList
        register={register}
        radioGroupValue={sbpThreePatientAttendantTravelDocument}
        onChange={onChange}
        error={
          errors.sbpThreePatientAttendantTravelDocument &&
          errors.sbpThreePatientAttendantTravelDocument.message
        }
        name='sbpThreePatientAttendantTravelDocument'
        className='add-case__location__section'
        title='Însoțitorul pacientului deține următoarele documente de călătorie'
        list={sbpThreePatientAttendantTravelDocumentFields}
      />

      <RadioButtonsList
        register={register}
        radioGroupValue={sbpThreeTransportToClinic}
        customInputValue={sbpThreeTransportToClinicCustomInput}
        onChange={onChange}
        error={
          (errors.sbpThreeTransportToClinic &&
            errors.sbpThreeTransportToClinic.message) ||
          (sbpThreeTransportToClinic === 'other' &&
            sbpThreeTransportToClinicCustomInput === '' &&
            'Required')
        }
        name='sbpThreeTransportToClinic'
        className='add-case__location__section'
        title='Transferul către o altă clinică din străinătate se face în baza'
        list={sbpThreeTransportToClinicFields}
      />

      <RadioButtonsList
        register={register}
        radioGroupValue={sbpThreeMedicalLandTransportNotes}
        customInputValue={sbpThreeMedicalLandTransportNotesCustomInput}
        onChange={onChange}
        error={
          (errors.sbpThreeMedicalLandTransportNotes &&
            errors.sbpThreeMedicalLandTransportNotes.message) ||
          (sbpThreeMedicalLandTransportNotes === 'other' &&
            sbpThreeMedicalLandTransportNotesCustomInput === '' &&
            'Required')
        }
        name='sbpThreeMedicalLandTransportNotes'
        className='add-case__location__section'
        title='Aveți și alte mențiuni în legătură cu transportul terestru medical?'
        list={sbpThreeMedicalLandTransportNotesFields}
      />

      <RadioButtonsList
        register={register}
        radioGroupValue={sbpThreePatientLocation}
        customInputValue={sbpThreePatientLocationCustomInput}
        onChange={onChange}
        error={
          (errors.sbpThreePatientLocation &&
            errors.sbpThreePatientLocation.message) ||
          (sbpThreePatientLocation === 'other' &&
            sbpThreePatientLocationCustomInput === '' &&
            'Required')
        }
        name='sbpThreePatientLocation'
        className='add-case__location__section'
        title='Unde se află în acest moment pacientul?'
        list={sbpThreePatientLocationFields}
      />
    </>
  );
};

export default CaseSubstepThree;
