import React, { forwardRef, useRef } from 'react';
import cx from 'classnames';

// import xmarkSolid from '../../../assets/xmark-solid.svg';
import xmarkSolid from '../../assets/xmark-solid.svg';

import './style.scss';

const InputFile = forwardRef(
  (
    {
      id = '',
      name = '',
      label = '',
      optionalText = '',
      error = '',
      accept = '',
      handleRemoveFile = () => {},
      multiple = false,
      message = 'File',
      uploadedFiles = {},
      className = '',
      onChange = () => {},
      ...rest
    },
    ref,
  ) => {
    const fileRef = useRef(null);
    return (
      <div
        className={cx('input-wrapper', 'input-wrapper__file', className, {
          'input-wrapper--error': error?.message,
        })}
      >
        <label
          htmlFor={id}
          className={cx('input-label', 'input-file', 'input-file--preview')}
        >
          <span className={cx({ 'input-wrapper--error': error?.message })}>
            {label}
          </span>
          {optionalText && (
            <div
              className={cx('subtext', {
                'input-wrapper--error': error?.message,
              })}
            >
              {optionalText}
            </div>
          )}
        </label>
        <input
          id={id}
          type='file'
          multiple={multiple}
          accept={accept}
          onChange={onChange}
          // onChange={handleChange}
          ref={fileRef || ref}
          {...rest}
        />
        {uploadedFiles?.[name] && (
          <div>
            {uploadedFiles?.[name].map((file, index) => (
              <>
                <span key={`${file.lastModified}${file.name}`}>
                  {file?.name}
                </span>
                <img
                  src={xmarkSolid}
                  alt='remove-file'
                  onClick={(e) => {
                    fileRef.current.value = null;
                    handleRemoveFile(name, index);
                  }}
                />
              </>
            ))}
          </div>
        )}
        {error?.message && (
          <span className='input-validation'>{error.message}</span>
        )}
      </div>
    );
  },
);

InputFile.displayName = 'InputFile';
export default InputFile;
