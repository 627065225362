import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import TokenHandler from '../util/token-handler.js';

const useHandleErrors = (errors = []) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!Array.isArray(errors)) {
      handleError(errors, navigate);
    } else {
      handleError(
        errors.filter((error) => [404, 403, 401].includes(error?.status))[0],
        navigate,
      );
    }
  }, [errors]);
};

const handleError = (error, navigate) => {
  if (error?.status === 404) {
    navigate('/404', { replace: true });
  } else if (error?.status === 403) {
    //
  } else if (error?.status === 401) {
    TokenHandler.handleExpiredToken();
  }
};

export default useHandleErrors;
