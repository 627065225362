import React, { forwardRef } from 'react';
import cx from 'classnames';

import Input from '../input/index.jsx';

import './style.scss';

const RadioButton = ({
  id,
  value,
  label,
  checked,
  onChange,
  inputValue,
  className,
  register,
  name,
}) => {
  const hasInput = value === 'other'; // Define when to show the input field

  return (
    <div className='radio-button__wrapper'>
      <Input
        className={cx('radio-button', className)}
        id={id}
        type='radio'
        name={name}
        value={value}
        label={label}
        checked={checked}
        {...register(name)}
        onChange={(e) => onChange(e, id)}
      />
      {hasInput && (
        <Input
          type='text'
          name={`${name}CustomInput`}
          value={inputValue}
          {...register(`${name}CustomInput`, {
            required: 'Custom input is required',
          })}
          onChange={(e) => onChange(e, id)}
        />
      )}
    </div>
  );
};

export default RadioButton;
