import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import loadingSpinner from '../../assets/images/spinner.png';
import './style.scss';

const Spinner = ({ overlay = true, className = '', text = '' }) => {
  return overlay ? (
    <div data-testid='spinner' className={cx('overlay', className)}>
      <div className='overlay__wrapper'>
        <img className='overlay__spinner' src={loadingSpinner} />
        {text && <p className='overlay__spinner__text'>{text}</p>}
      </div>
    </div>
  ) : (
    <div className={cx('simple-spinner', className)}>
      <img src={loadingSpinner} />
    </div>
  );
};

Spinner.propTypes = {
  overlay: PropTypes.bool,
  className: PropTypes.string,
  text: PropTypes.string,
};

export default Spinner;
