import React, { useState } from 'react';
import {
  useGetCaseQuery,
  useUpdateCaseMutation,
} from '../../redux/api/case.js';
import { useParams, useNavigate, useLocation } from 'react-router';
import Grid from '@mui/material/Grid';

import MDBox from '../../components/MDBox';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import OrdersOverview from '../../layouts/dashboard/components/OrdersOverview';

import Attachments from '../../containers/attachments/index.js';
import MDButton from '../../components/MDButton';

import Button from '../../components/button/index.jsx';
import {
  dataUsageAgreementFields,
  dataUsageAgreementFieldsMapped,
  landTransportAttendantMapped,
  patientLocationMapped,
  patientRequirementMapped,
  patientTreatmentInRomaniaFieldsMapped,
  STYLES as STYLE,
  transportNeedsMapped,
  transportNotesMapped,
  transportToClinicMapped,
  clinicAcceptanceMapped,
  patientRelationshipMapped,
  patientTravelDocumentMapped,
  patientAttendantTravelDocumentMapped,
  patientRepresentativeDocumentTypeFields,
  patientRepresentativeDocumentTypeMapped,
  PROJECT_URL,
  patientRelationship,
} from '../../constants/index.js';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import GDPRPhoto from '../../pdfs/gdpr-photo/index.jsx';
import GDPR from '../../pdfs/gdpr';
import useHandleErrors from '../../hooks/useHandleErrors.js';

import './style.scss';
import FinalRequest from '../../pdfs/final-request/index.jsx';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import Spinner from 'components/spinner/index.jsx';

import { calculateAge } from '../../util/patient.js';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { caseStatus } from '../../constants';
import Input from '../../components/input';
import { formatDate } from '../../util/date';

const Case = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [mode, setMode] = useState();
  const {
    data: { data: caseData } = {},
    isLoading,
    isFetching,
    error,
  } = useGetCaseQuery(params.id);

  const [
    updateCase,
    { isLoading: isUpdateCaseLoading, error: updateCaseError },
  ] = useUpdateCaseMutation();

  useHandleErrors([error, updateCaseError]);

  const [menu, setMenu] = useState(null);

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);

  const handleStatusChange = (statusValue) => {
    try {
      updateCase({
        id: params.id,
        body: {
          status: statusValue,
        },
      }).unwrap();
    } catch (e) {
      console.log(e);
    }
    closeMenu();
  };

  const renderMenu = (
    <Menu
      id='simple-menu'
      anchorEl={menu}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      {Object.entries(caseStatus).map(([key, caseStatus]) => (
        <MenuItem
          sx={{ textTransform: 'capitalize' }}
          key={key}
          onClick={() => handleStatusChange(key)}
        >
          {caseStatus}
        </MenuItem>
      ))}
    </Menu>
  );

  return (
    <>
      {/* <PDFViewer width="100vw" height="100vh">
        <GDPR />
      </PDFViewer> */}
      {(isLoading || isFetching || isUpdateCaseLoading) && <Spinner />}
      <DashboardLayout>
        <DashboardNavbar position='absolute' />
        <section className='case__wrapper'>
          <header className='case__header'>
            <h1>Caz #{caseData?.id}</h1>
          </header>

          <MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={8}>
                <Card>
                  <CardContent>
                    <section>
                      <MDBox p={2} mt='auto'>
                        <MDButton
                          component='a'
                          target='_blank'
                          rel='noreferrer'
                          variant='gradient'
                          color='info'
                          size='small'
                          onClick={() => {
                            navigate(`/edit-case/${params.id}`, {
                              state: {
                                prevUrl: location.pathname,
                              },
                            });
                          }}
                        >
                          {caseData?.requestedCase
                            ? 'Completează cererea'
                            : 'Editează'}
                        </MDButton>
                      </MDBox>
                    </section>
                    <section className='case__body'>
                      <section className='case__body__content'>
                        <p>
                          Nume și prenume solicitant:{' '}
                          {caseData?.firstAndLastName || 'N/A'}
                        </p>
                        <p>
                          Numărul de telefon al solicitantului:{' '}
                          {caseData?.phoneNumber || 'N/A'}
                        </p>
                        <p>
                          Email-ul solicitantului: {caseData?.email || 'N/A'}
                        </p>

                        <p>
                          Solicitarea pacientului:{' '}
                          {patientRequirementMapped[
                            caseData?.patientRequirement
                          ] || 'N/A'}
                        </p>
                        <p>
                          Nume și prenume pacient:{' '}
                          {caseData?.firstAndLastName || 'N/A'}
                        </p>

                        <p>CNP pacient: {caseData?.patientPIN || 'N/A'}</p>

                        <p>
                          Vârsta pacientului:{' '}
                          {formatDate(caseData?.patientAge) || 'N/A'}
                          {/*{calculateAge(caseData?.patientAge) || 'N/A'}*/}
                        </p>
                        <p>
                          Diagnosticul pacientului:{' '}
                          {caseData?.Diagnoses?.length > 0
                            ? caseData?.Diagnoses?.map((diagnosis) => (
                                <p
                                  key={`${diagnosis.id}${diagnosis.patientDiagnosis}`}
                                >
                                  {diagnosis.patientDiagnosis};
                                </p>
                              ))
                            : 'N/A'}
                        </p>
                        <p>
                          Istoricul pacientului pe scurt :{' '}
                          {caseData?.patientHistory || 'N/A'}
                        </p>
                        <p>
                          Nume și prenume reprezentant pacient :{' '}
                          {caseData?.patientRepresentative || 'N/A'}
                        </p>

                        <p>
                          Data nașterii reprezentant pacient :{' '}
                          {formatDate(caseData?.patientRepresentativeAge) ||
                            'N/A'}
                        </p>

                        <p>
                          Reprezentant pacient născut în localitatea:{' '}
                          {formatDate(
                            caseData?.patientRepresentativeAddressCityBorn,
                          ) || 'N/A'}
                        </p>

                        <p>
                          Calitate față de pacient :{' '}
                          {caseData?.patientRelationshipCustomInput &&
                          caseData?.patientRelationshipCustomInput !== ''
                            ? caseData?.patientRelationshipCustomInput
                            : patientRelationshipMapped[
                                caseData?.patientRelationship
                              ] || 'N/A'}
                        </p>

                        <p>
                          Adresă de e-mail reprezentant pacient :{' '}
                          {caseData?.patientRepresentativeEmail || 'N/A'}
                        </p>
                        <p>
                          Număr de telefon reprezentant pacient :{' '}
                          {caseData?.patientRepresentativePhoneNumber || 'N/A'}
                        </p>
                        <p>
                          Adresă reprezentant pacient : Strada{' '}
                          {caseData?.patientRepresentativeAddressStreet ||
                            'N/A'}
                          , județ{' '}
                          {caseData?.patientRepresentativeAddressCounty ||
                            'N/A'}
                          , localitate{' '}
                          {caseData?.patientRepresentativeAddressCity || 'N/A'},
                          sector{' '}
                          {caseData?.patientRepresentativeAddressCitySector ||
                            'N/A'}
                          , bloc{' '}
                          {caseData?.patientRepresentativeAddressStreetBlock ||
                            'N/A'}
                          , scara{' '}
                          {caseData?.patientRepresentativeAddressBlockEntrance ||
                            'N/A'}
                          , etaj{' '}
                          {caseData?.patientRepresentativeAddressBlockFloor ||
                            'N/A'}
                          , apartament{' '}
                          {caseData?.patientRepresentativeAddressBlockApartment ||
                            'N/A'}
                        </p>
                        <p>
                          CNP reprezentant pacient :{' '}
                          {caseData?.patientRepresentativePIN || 'N/A'}
                        </p>
                        <p>
                          Tip document reprezentant pacient :{' '}
                          {patientRepresentativeDocumentTypeMapped[
                            caseData?.patientRepresentativeDocumentType
                          ] || 'N/A'}
                        </p>
                        <p>
                          Seria{' '}
                          {caseData?.patientRepresentativeDocumentType ===
                          patientRepresentativeDocumentTypeFields[0].value
                            ? 'C.I.'
                            : caseData?.patientRepresentativeDocumentType ===
                              patientRepresentativeDocumentTypeFields[1].value
                            ? 'pașaport'
                            : 'C.I./pașaport'}{' '}
                          reprezentant pacient :{' '}
                          {caseData?.patientRepresentativeSN || 'N/A'}
                        </p>
                        <p>
                          Nr.{' '}
                          {caseData?.patientRepresentativeDocumentType ===
                          patientRepresentativeDocumentTypeFields[0].value
                            ? 'C.I.'
                            : caseData?.patientRepresentativeDocumentType ===
                              patientRepresentativeDocumentTypeFields[1].value
                            ? 'pașaport'
                            : 'C.I./pașaport'}{' '}
                          reprezentant pacient :{' '}
                          {caseData?.patientRepresentativeSNNo || 'N/A'}
                        </p>

                        <p>
                          Eliberat de :{' '}
                          {caseData?.patientRepresentativeDocumentIssuedBy ||
                            'N/A'}
                        </p>
                        <p>
                          La data :{' '}
                          {formatDate(
                            caseData?.patientRepresentativeDocumentIssueDate,
                          ) || 'N/A'}
                        </p>

                        <p>
                          Tratamentul se poate efectua pe teritoriul României? :{' '}
                          {patientTreatmentInRomaniaFieldsMapped[
                            caseData?.patientTreatmentInRomania
                          ] || 'N/A'}
                        </p>
                        <p>
                          Pacientul internat în :{' '}
                          {caseData?.patientHospital || 'N/A'}
                        </p>
                        <p>
                          Secția spitalului :{' '}
                          {caseData?.hospitalSection || 'N/A'}
                        </p>
                        <p>
                          Doctor curant : {caseData?.patientDoctor || 'N/A'}
                        </p>
                        <p>
                          Contact doctor: {caseData?.doctorContact || 'N/A'}
                        </p>
                        <p>
                          Dată internare pacient:{' '}
                          {formatDate(caseData?.hospitalizationDate) || 'N/A'}
                        </p>
                        <p>
                          Accept primire clinică:{' '}
                          {clinicAcceptanceMapped[caseData?.clinicAcceptance] ||
                            'N/A'}
                        </p>
                        <p>
                          Programare clinică din străinătate:{' '}
                          {formatDate(caseData?.clinicAppointment) || 'N/A'}
                        </p>
                        <p>
                          Contact reprezentat clinică din străinătate:{' '}
                          {caseData?.clinicContact || 'N/A'}
                        </p>
                        <p>
                          Locul de plecare și locul de destinație al
                          transportului aerian medical :{' '}
                          {caseData?.departureAndDestination || 'N/A'}
                        </p>
                        <p>
                          Însoțitor pacient:{' '}
                          {caseData?.transportAttendantCustomInput &&
                          caseData?.transportAttendantCustomInput !== ''
                            ? caseData?.transportAttendantCustomInput
                            : caseData?.transportAttendant
                            ? landTransportAttendantMapped[
                                caseData?.transportAttendant
                              ]
                            : 'N/A'}
                        </p>
                        <p>
                          Documente de călătorie pacient:{' '}
                          {patientTravelDocumentMapped[
                            caseData?.patientTravelDocument
                          ] || 'N/A'}
                        </p>
                        <p>
                          Documente de călătorie însoțitor:{' '}
                          {patientAttendantTravelDocumentMapped[
                            caseData?.patientAttendantTravelDocument
                          ] || 'N/A'}
                        </p>
                        <p>
                          Transferul către o altă clinică din străinătate se
                          face în baza:{' '}
                          {caseData?.transportToClinicCustomInput &&
                          caseData?.transportToClinicCustomInput !== ''
                            ? caseData?.transportToClinicCustomInput
                            : caseData?.transportToClinic
                            ? transportToClinicMapped[
                                caseData?.transportToClinic
                              ]
                            : 'N/A'}
                        </p>
                        <p>
                          Nevoi referitoare la transport:{' '}
                          {caseData?.medicalTransportNeedsCustomInput &&
                          caseData?.medicalTransportNeedsCustomInput !== ''
                            ? caseData?.medicalTransportNeedsCustomInput
                            : caseData?.medicalTransportNeeds
                            ? transportNeedsMapped[
                                caseData?.medicalTransportNeeds
                              ]
                            : 'N/A'}
                        </p>
                        <p>
                          Mențiuni în legătură cu transportul aerian medical:{' '}
                          {caseData?.medicalTransportNotesCustomInput &&
                          caseData?.medicalTransportNotesCustomInput !== ''
                            ? caseData?.medicalTransportNotesCustomInput
                            : caseData?.medicalTransportNotes
                            ? transportNotesMapped[
                                caseData?.medicalTransportNotes
                              ]
                            : 'N/A'}
                        </p>
                        <p>
                          Locația pacientului:{' '}
                          {caseData?.patientLocationCustomInput &&
                          caseData?.patientLocationCustomInput !== ''
                            ? caseData?.patientLocationCustomInput
                            : patientLocationMapped[
                                caseData?.patientLocation
                              ] || 'N/A'}
                        </p>
                        <p>
                          Acord utilizare informații:{' '}
                          {dataUsageAgreementFieldsMapped[
                            caseData?.dataUsageAgreement
                          ] || 'N/A'}
                        </p>
                      </section>
                      {!isLoading && (
                        <section className='case__body__footer'>
                          <Grid container spacing={2}>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={4}
                              className='case__body__footer__item'
                            >
                              <Button style={STYLE.PINK}>
                                <PDFDownloadLink
                                  document={
                                    <GDPR
                                      patientFirstAndLastName={
                                        caseData?.patientFirstAndLastName
                                      }
                                      patientRepresentative={
                                        caseData?.patientRepresentative
                                      }
                                      patientRepresentativePIN={
                                        caseData?.patientRepresentativePIN
                                      }
                                      patientRepresentativeAge={
                                        caseData?.patientRepresentativeAge
                                      }
                                      patientRepresentativeEmail={
                                        caseData?.patientRepresentativeEmail
                                      }
                                      patientRepresentativePhoneNumber={
                                        caseData?.patientRepresentativePhoneNumber
                                      }
                                      patientRepresentativeAddressCityBorn={
                                        caseData?.patientRepresentativeAddressCityBorn
                                      }
                                      patientRepresentativeAddressCity={
                                        caseData?.patientRepresentativeAddressCity
                                      }
                                      patientRepresentativeAddressStreet={
                                        caseData?.patientRepresentativeAddressStreet
                                      }
                                      patientRepresentativeAddressStreetNo={
                                        caseData?.patientRepresentativeAddressStreetNo
                                      }
                                      patientRepresentativeAddressStreetBlock={
                                        caseData?.patientRepresentativeAddressStreetBlock
                                      }
                                      patientRepresentativeAddressBlockEntrance={
                                        caseData?.patientRepresentativeAddressBlockEntrance
                                      }
                                      patientRepresentativeAddressBlockApartment={
                                        caseData?.patientRepresentativeAddressBlockApartment
                                      }
                                      patientRepresentativeAddressCounty={
                                        caseData?.patientRepresentativeAddressCounty
                                      }
                                      patientRepresentativeDocumentType={
                                        caseData?.patientRepresentativeDocumentType
                                      }
                                      patientRepresentativeSN={
                                        caseData?.patientRepresentativeSN
                                      }
                                      patientRepresentativeSNNo={
                                        caseData?.patientRepresentativeSNNo
                                      }
                                      patientRepresentativeDocumentIssuedBy={
                                        caseData?.patientRepresentativeDocumentIssuedBy
                                      }
                                      patientRepresentativeDocumentIssueDate={
                                        caseData?.patientRepresentativeDocumentIssueDate
                                      }
                                    />
                                  }
                                  fileName='GDPR.pdf'
                                >
                                  {({ blob, url, loading, error }) =>
                                    loading
                                      ? 'Loading document...'
                                      : 'Generează GDPR'
                                  }
                                </PDFDownloadLink>
                              </Button>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={4}
                              className='case__body__footer__item'
                            >
                              <Button style={STYLE.PINK}>
                                <PDFDownloadLink
                                  document={
                                    <GDPRPhoto
                                      patientFirstAndLastName={
                                        caseData?.patientFirstAndLastName
                                      }
                                      patientRepresentative={
                                        caseData?.patientRepresentative
                                      }
                                      patientRepresentativePIN={
                                        caseData?.patientRepresentativePIN
                                      }
                                      patientRepresentativeAge={
                                        caseData?.patientRepresentativeAge
                                      }
                                      patientRepresentativeEmail={
                                        caseData?.patientRepresentativeEmail
                                      }
                                      patientRepresentativePhoneNumber={
                                        caseData?.patientRepresentativePhoneNumber
                                      }
                                      patientRepresentativeAddressCityBorn={
                                        caseData?.patientRepresentativeAddressCityBorn
                                      }
                                      patientRepresentativeAddressCity={
                                        caseData?.patientRepresentativeAddressCity
                                      }
                                      patientRepresentativeAddressStreet={
                                        caseData?.patientRepresentativeAddressStreet
                                      }
                                      patientRepresentativeAddressStreetNo={
                                        caseData?.patientRepresentativeAddressStreetNo
                                      }
                                      patientRepresentativeAddressBlockFloor={
                                        caseData?.patientRepresentativeAddressBlockFloor
                                      }
                                      patientRepresentativeAddressStreetBlock={
                                        caseData?.patientRepresentativeAddressStreetBlock
                                      }
                                      patientRepresentativeAddressBlockEntrance={
                                        caseData?.patientRepresentativeAddressBlockEntrance
                                      }
                                      patientRepresentativeAddressBlockApartment={
                                        caseData?.patientRepresentativeAddressBlockApartment
                                      }
                                      patientRepresentativeAddressCounty={
                                        caseData?.patientRepresentativeAddressCounty
                                      }
                                      patientRepresentativeDocumentType={
                                        caseData?.patientRepresentativeDocumentType
                                      }
                                      patientRepresentativeSN={
                                        caseData?.patientRepresentativeSN
                                      }
                                      patientRepresentativeSNNo={
                                        caseData?.patientRepresentativeSNNo
                                      }
                                      patientRepresentativeDocumentIssuedBy={
                                        caseData?.patientRepresentativeDocumentIssuedBy
                                      }
                                      patientRepresentativeDocumentIssueDate={
                                        caseData?.patientRepresentativeDocumentIssueDate
                                      }
                                      patientAge={formatDate(
                                        caseData?.patientAge,
                                      )}
                                      patientPIN={caseData?.patientPIN}
                                      patientRelationship={
                                        caseData?.patientRelationshipCustomInput !==
                                        ''
                                          ? caseData?.patientRelationshipCustomInput
                                          : patientRelationshipMapped[
                                              caseData?.patientRelationship
                                            ]
                                      }
                                      currentLocation={
                                        caseData?.patientLocationCustomInput !==
                                        ''
                                          ? caseData?.patientLocationCustomInput
                                          : patientLocationMapped[
                                              caseData?.patientLocation
                                            ]
                                      }
                                      // patientRequirementValue={
                                      //   caseData?.patientRequirement
                                      // }
                                      diagnosis={caseData?.patientDiagnosis}
                                      {...(caseData?.patientDoctor
                                        ? {
                                            patientDoctor:
                                              caseData?.patientDoctor,
                                          }
                                        : {})}
                                      {...(caseData?.patientHospital
                                        ? {
                                            patientHospital:
                                              caseData?.patientHospital,
                                          }
                                        : {})}
                                      {...(caseData?.hospitalSection
                                        ? {
                                            hospitalSection:
                                              caseData?.hospitalSection,
                                          }
                                        : {})}
                                    />
                                  }
                                  fileName='GDPR poze.pdf'
                                >
                                  {({ blob, url, loading, error }) =>
                                    loading
                                      ? 'Loading document...'
                                      : 'Generează GDPR poze'
                                  }
                                </PDFDownloadLink>
                              </Button>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={4}
                              className='case__body__footer__item'
                            >
                              <Button style={STYLE.PINK}>
                                <PDFDownloadLink
                                  document={
                                    <FinalRequest
                                      patientRepresentative={
                                        caseData?.patientRepresentative
                                      }
                                      patientFirstAndLastName={
                                        caseData?.patientFirstAndLastName
                                      }
                                      phoneNumber={caseData?.phoneNumber}
                                      email={caseData?.email}
                                      patientAge={calculateAge(
                                        caseData?.patientAge,
                                      )}
                                      diagnoses={caseData?.Diagnoses}
                                    />
                                  }
                                  fileName='Cerere Finala.pdf'
                                >
                                  {({ blob, url, loading, error }) =>
                                    loading
                                      ? 'Loading document...'
                                      : 'Generează Cerere Finală'
                                  }
                                </PDFDownloadLink>
                              </Button>
                            </Grid>
                          </Grid>
                        </section>
                      )}
                    </section>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <MDBox color='text' px={2} sx={{ marginBottom: '1rem' }}>
                  Status:
                  {caseData?.status && (
                    <MDButton
                      variant='outlined'
                      color='info'
                      sx={{
                        cursor: 'pointer',
                        fontWeight: 'bold',
                      }}
                      fontSize='small'
                      onClick={openMenu}
                    >
                      {caseStatus[caseData?.status]}
                    </MDButton>
                  )}
                </MDBox>
                {renderMenu}
                <Attachments list={caseData?.attachments} />
              </Grid>
            </Grid>
          </MDBox>
        </section>
      </DashboardLayout>
    </>
  );
};

export default Case;
