import { stepFields } from '../constants/index.js';

// export const getFormData = (data, stepsTaken) => {
//   console.log('data', data);
//   console.log('stepsTaken', stepsTaken);
//   const neededFields = [];

//   stepsTaken.forEach((item) => {
//     neededFields.push(...stepFields[item.step][item.substep]);
//   });

//   const formData = [];
//   console.log('neededFields', neededFields);
//   neededFields.forEach((field) => {
//     if (data.hasOwnProperty(field)) {
//       formData.push({ [field]: data[field] });
//     }
//   });
//   console.log('formData', formData);
//   return formData;
// };

export const getFormData = (data, stepsTaken, dirtyFields) => {
  const neededFields = [];

  stepsTaken.forEach((item) => {
    neededFields.push(...stepFields[item.step][item.substep]);
  });

  const formData = {}; // Change from array to object

  neededFields.forEach((field) => {
    if (dirtyFields && dirtyFields[field] && data.hasOwnProperty(field)) {
      formData[field] = data[field]; // Use field as the key in the object
    } else if (!dirtyFields && data.hasOwnProperty(field)) {
      formData[field] = data[field]; // Use field as the key in the object
    }
  });

  return formData;
};

export const capitalize = (str) => {
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
};
