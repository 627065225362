import React from 'react';

import CaseSubstepOne from './case-substep-one/index.jsx';

// const schema = Yup.object({
//   firstAndLastName: Yup.string().required(),
//   radioGroup: Yup.string().required(),
//   patientRequirement: Yup.string().required(),
// });
const CaseStepThree = ({
  register,
  errors,
  trigger,
  setValue,
  setDirtyFields,
  watch,
  substep,
  handleFileUpload,
  handleRemoveFile,
  uploadedFiles,
  control,
}) => {
  const substeps = {
    1: (
      <CaseSubstepOne
        register={register}
        errors={errors}
        trigger={trigger}
        setValue={setValue}
        setDirtyFields={setDirtyFields}
        watch={watch}
        handleFileUpload={handleFileUpload}
        handleRemoveFile={handleRemoveFile}
        uploadedFiles={uploadedFiles}
        control={control}
      />
    ),
  };

  return <>{substeps[substep]}</>;
};

export default CaseStepThree;
