import React, { useParams } from 'react';
import PropTypes from 'prop-types';

// @mui material components
import Icon from '@mui/material/Icon';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';

import { API_URL } from '../../constants';

import useHandleErrors from '../../hooks/useHandleErrors';

import Spinner from 'components/spinner';

import { useLazyGetAttachmentQuery } from '../../redux/api/case';
import { useDeleteCaseAttachmentMutation } from '../../redux/api/case';
import axios from 'axios';

const Attachment = ({ name, caseId, price, noGutter }) => {
  // const [downloadAttachment, { data, isLaoding, error }] =
  //   useLazyGetAttachmentQuery();

  const [deleteAttachment, { isLoading, error }] =
    useDeleteCaseAttachmentMutation();

  useHandleErrors(error);

  const handleDownload = async () => {
    try {
      const jwtToken = localStorage.getItem('token');
      await fetch(
        `${API_URL}/cases/${caseId}/download?fileName=${encodeURIComponent(
          name,
        )}`,
        {
          credentials: 'include',
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json', // Adjust content type if needed
          },
        },
      )
        .then(async (response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const filename = response.headers.get('Content-Disposition');
          // .split('filename=')[1];
          const blobRes = await response.blob();
          return { filename, blobRes };
        })
        .then(({ filename, blobRes }) => {
          const fileurl = URL.createObjectURL(blobRes);
          const link = document.createElement('a');
          link.href = fileurl;
          link.setAttribute('download', `sample.${name}`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error('Error downloading file:', error);
        });
    } catch (e) {
      console.error('Unexpected error:', e);
    }
  };

  const handleDeleteAttachment = async () => {
    try {
      deleteAttachment({ attachment: name, id: caseId }).unwrap();
    } catch (error) {
      console.error('Unexpected error:', e);
    }
  };

  return (
    <>
      {isLoading && <Spinner />}
      <MDBox
        component='li'
        display='flex'
        justifyContent='space-between'
        // py={1}
        // pr={1}
        mb={noGutter ? 0 : 1}
        sx={{
          flexDirection: { xs: 'column', sm: 'row', md: 'column' },
        }}
      >
        <MDBox lineHeight={1.125}>
          <MDTypography display='block' variant='button' fontWeight='medium'>
            {name}
          </MDTypography>
        </MDBox>

        <MDBox
          display='flex'
          alignItems='center'
          mt={{ xs: 2, sm: 0 }}
          ml={{ sm: 0 }}
        >
          <MDBox mr={1}>
            <MDButton
              variant='outlined'
              color='error'
              display='flex'
              alignItems='center'
              size='small'
              onClick={handleDeleteAttachment}
            >
              <Icon>delete</Icon>
              <span>delete</span>
            </MDButton>
          </MDBox>
          <MDButton
            variant='outlined'
            color={'dark'}
            display='flex'
            alignItems='center'
            size='small'
            onClick={handleDownload}
          >
            <Icon>download</Icon>
            <span>download</span>
          </MDButton>
        </MDBox>
      </MDBox>
    </>
  );
};

// Setting default values for the props of Invoice
Attachment.defaultProps = {
  noGutter: false,
};

// Typechecking props for the Invoice
Attachment.propTypes = {
  date: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
};

export default Attachment;
