import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './style.scss';

/**
 * @Component for container card
 */
const ContainerCard = ({
  children,
  className,
  onClick = () => {},
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  ariaLabel = '',
  key = null,
}) => {
  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onFocus={onMouseEnter}
      className={cx('container-card', className)}
      aria-label={ariaLabel}
      onClick={onClick}
      key={key}
    >
      {children}
    </div>
  );
};

ContainerCard.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  tabIndex: PropTypes.number,
  ariaLabel: PropTypes.string,
};

export default ContainerCard;
