export const calculateAge = (dateString) => {
  if (dateString === '') {
    return null;
  }

  const birthDate = new Date(dateString);
  const currentDate = new Date();

  let years = currentDate.getFullYear() - birthDate.getFullYear();
  let months = currentDate.getMonth() - birthDate.getMonth();

  // Check if the birthday has occurred this year
  if (
    currentDate.getMonth() < birthDate.getMonth() ||
    (currentDate.getMonth() === birthDate.getMonth() &&
      currentDate.getDate() < birthDate.getDate())
  ) {
    // Subtract 1 year if the birthday hasn't occurred yet this year
    years--;
  }
  if (years === 0) {
    // If the age is 0 years, return the number of months
    return months === 1 ? `${months} lună` : `${months} luni`;
  } else {
    return years === 1 ? `${years} an` : `${years} ani`;
  }
};
