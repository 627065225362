import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import Input from '../../components/input/index.jsx';
import Button from '../../components/button/index.jsx';
import { STYLES as STYLE } from '../../constants/index.js';
import { useLoginMutation, useSignupMutation } from '../../redux/api/user.js';
import './style.scss';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const schema = Yup.object({
  email: Yup.string().email('Invalid Email').required('Email is required'),
  phoneNumber: Yup.string().matches(
    /^(07[2-8])(\d{7})$/,
    'Phone number is not valid',
  ),
  password: Yup.string()
    .required('Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*[\]{}()?"\\,><':;|_~`=+-])[a-zA-Z\d!@#$%^&*[\]{}()?"\\,><':;|_~`=+-]{8,99}$/,
      'Must contain at least 8 Characters, 1 Uppercase, 1 Lowercase, 1 Special Character, and 1 Number',
    ),
  confirmPassword: Yup.string()
    .required('Confirm password is required')
    .oneOf([Yup.ref('password')], 'Passwords do not match'),
});

const Signup = () => {
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [triggerLogin, { isLoading }] = useLoginMutation();

  const onSubmit = async (data) => {
    try {
      const param = data.email_or_phone.includes('@')
        ? { email: data.email_or_phone }
        : { phone: data.email_or_phone };
      const loginData = await triggerLogin({
        ...param,
        password: data.password,
      }).unwrap();
      localStorage.setItem('token', loginData);
      navigate('/');
    } catch (error) {
      console.log('err login', error);
      setError('email', {
        type: 'server side',
      });
      setError('password', {
        type: 'server side',
        message: error.data.message,
      });
    }
  };

  return (
    <section className='sign-up'>
      <h2>Create an account</h2>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='sign-up__wrapper__form'
      >
        <Input
          placeholder='Email'
          type='email'
          name='email'
          label='Email'
          error={errors.email}
          {...register('email', {
            onChange: () => {
              (isValid || errors.email) && trigger('email');
            },
          })}
        ></Input>
        <Input
          placeholder='Phone Number'
          type='phoneNumber'
          name='phoneNumber'
          label='Phone Number'
          error={errors.phoneNumber}
          {...register('phoneNumber', {
            onChange: () => {
              (isValid || errors.phoneNumber) && trigger('phoneNumber');
            },
          })}
        ></Input>
        <Input
          placeholder='Password'
          type='password'
          name='password'
          label='Password'
          error={errors.password}
          {...register('password', {
            onChange: () => {
              (isValid || errors.password) && trigger('password');
            },
          })}
        ></Input>
        <Input
          placeholder='Confirm Password'
          type='password'
          name='confirmPassword'
          label='Confirm Password'
          error={errors.confirmPassword}
          {...register('confirmPassword', {
            onChange: () => {
              (isValid || errors.confirmPassword) && trigger('confirmPassword');
            },
          })}
        ></Input>
        <Button style={STYLE.PINK} type='submit'>
          Sign up
        </Button>
      </form>
      <p>
        Already a user? <a href='/login'>Log in</a>
      </p>
    </section>
  );
};

export default Signup;
