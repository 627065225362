import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './style.scss';

const Input = forwardRef(
  (
    {
      placeholder = 'Your answer',
      type = 'text',
      name = '',
      label = '',
      id = '',
      optionalText = null,
      error = null,
      className = '',
      tabIndex = 0,
      ...rest
    },
    ref,
  ) => {
    // console.log('id', id);
    return (
      <>
        <div
          className={cx('input-wrapper', className, {
            'input-wrapper--error': error?.message,
          })}
        >
          <label tabIndex={tabIndex} htmlFor={id} className='input-label'>
            <span className={cx({ 'input-wrapper--error': error?.message })}>
              {label}
            </span>
            {optionalText && (
              <div
                className={cx('subtext', {
                  'input-wrapper--error': error?.message,
                })}
              >
                {optionalText}
              </div>
            )}
          </label>
          <input
            className={cx('input', {
              'input--invalid': error,
            })}
            name={name}
            type={type}
            ref={ref}
            tabIndex={tabIndex}
            id={id}
            placeholder={placeholder}
            {...rest}
          />
          {error?.message && (
            <span className='input-validation' tabIndex={tabIndex}>
              {error.message}
            </span>
          )}
        </div>
      </>
    );
  },
);

Input.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  optionalText: PropTypes.string,
  error: PropTypes.object,
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  isRadioButton: PropTypes.bool,
};
Input.displayName = 'Input';

export default Input;
