import { configureStore } from '@reduxjs/toolkit';

import userSlice from './reducers/user.js';
import navSlice from './reducers/nav.js';

import api from './api/api.js';

const store = configureStore({
  reducer: {
    user: userSlice,
    nav: navSlice,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
  devTools: true,
});
export default store;
