import React from 'react';

import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';

import './style.scss';

const FourOFour = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar position='absolute' />

      <section className='fof__wrapper'>
        <h1>404</h1>
      </section>
    </DashboardLayout>
  );
};

export default FourOFour;
