import React, { useRef, useState } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Input from '../../components/input/index.jsx';
import RadioButtonsList from '../../components/radio-buttons-list/index.jsx';
import {
  patientLocationFields,
  patientRelationship,
  patientRequirement,
  sbpThreePatientLocationFields,
  sbpTwoPatientLocationFields,
  stepFields,
  STYLES as STYLE,
} from '../../constants/index.js';
import Button from '../../components/button/index.jsx';
import MDBox from '../../components/MDBox';
import Card from '@mui/material/Card';
import MDTypography from '../../components/MDTypography';

import './style.scss';
import AddCaseContent from '../../components/add-case-content/index.jsx';
import { getFormData } from '../../util/form.js';
import { useAddCaseMutation } from '../../redux/api/case.js';
import { useAddRequestCaseMutation } from '../../redux/api/requestCase.js';
import { useNavigate } from 'react-router-dom';
import useHandleErrors from '../../hooks/useHandleErrors.js';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import MDButton from '../../components/MDButton';
import Spinner from '../../components/spinner';
import BasicLayout from '../../layouts/authentication/components/BasicLayout';

const schema = Yup.object({
  firstAndLastName: Yup.string().required('Câmp obligatioriu'),
  email: Yup.string().email('Email invalid').required('Câmp obligatioriu'),
  patientRelationship: Yup.string().required('Câmp obligatioriu'),
  patientRequirement: Yup.string().required('Câmp obligatioriu'),
  patientRepresentative: Yup.string().required('Câmp obligatioriu'),
  patientRepresentativeEmail: Yup.string().email('Email invalid'),
  patientRepresentativePhoneNumber: Yup.string(),
  patientRepresentativeAddressStreet: Yup.string(),
  patientRepresentativeAddressCounty: Yup.string(),
  patientRepresentativeAddressCity: Yup.string(),
  patientRepresentativeAddressCitySector: Yup.string(),
  patientRepresentativeAddressStreetNo: Yup.string(),
  patientRepresentativeAddressStreetBlock: Yup.string(),
  patientRepresentativeAddressBlockEntrance: Yup.string(),
  patientRepresentativeAddressBlockFloor: Yup.string(),
  patientRepresentativeAddressBlockApartment: Yup.string(),
  patientRepresentativePIN: Yup.string(),
  patientPIN: Yup.string(),
  patientRepresentativeDocumentType: Yup.string().nullable(true),
  patientRepresentativeSN: Yup.string(),
  patientRepresentativeSNNo: Yup.string(),
  patientRepresentativeDocumentIssuedBy: Yup.string(),
  patientRepresentativeDocumentIssueDate: Yup.string(),

  patientRelationshipCustomInput: Yup.string().when('patientRelationship', {
    is: 'other',
    then: (schema) => schema.required('Câmp obligatioriu'),
    otherwise: (schema) => schema.nullable(true),
  }),
  phoneNumber: Yup.string().required('Câmp obligatioriu'),
  patientFirstAndLastName: Yup.string().required('Câmp obligatioriu'),
  patientAge: Yup.string().required('Câmp obligatioriu'),
  // patientDiagnosis: Yup.string().required('Câmp obligatioriu'),
  diagnoses: Yup.array().of(
    Yup.object().shape({
      patientDiagnosis: Yup.string().required('Câmp obligatioriu'),
    }),
  ),
  patientMedicalDocuments: Yup.mixed().test(
    'fileRequired',
    'Încărcați cel puțin un fișier',
    (value) => {
      // Check if value is a FileList and it contains at least one file
      return value?.length > 0;
      // Validation fails
    },
  ),
  patientHistory: Yup.string().required('Câmp obligatioriu'),
  patientTreatmentInRomania: Yup.string().required('Câmp obligatioriu'),
  patientLocation: Yup.string().required('Câmp obligatioriu'),
  // sbpTwoPatientFirstAndLastName: Yup.string().required('Câmp obligatioriu'),
  // sbpTwoPatientAge: Yup.string().required('Câmp obligatioriu'),
  // sbpTwoPatientDiagnosis: Yup.string().required('Câmp obligatioriu'),

  // sbpTwoDiagnoses: Yup.array().of(
  //   Yup.object().shape({
  //     patientDiagnosis: Yup.string().required('Câmp obligatioriu'),
  //   }),
  // ),
  sbpTwoPatientMedicalDocuments: Yup.mixed().test(
    'fileRequired',
    'Please upload at least one file',
    (value) => {
      // Check if value is a FileList and it contains at least one file
      return value?.length > 0;
      // Validation fails
    },
  ),
  sbpTwoPatientHistory: Yup.string().required('Câmp obligatioriu'),
  sbpTwoPatientTreatmentInRomania: Yup.string().required('Câmp obligatioriu'),
  sbpTwoClinicAcceptance: Yup.string().required('Câmp obligatioriu'),
  sbpTwoClinicContact: Yup.string().required('Câmp obligatioriu'),
  sbpTwoDepartureAndDestinationAerial:
    Yup.string().required('Câmp obligatioriu'),
  sbpTwoAirTransportAttendant: Yup.string().required('Câmp obligatioriu'),
  sbpTwoPatientTravelDocument: Yup.string().required('Câmp obligatioriu'),
  sbpTwoPatientAttendantTravelDocument:
    Yup.string().required('Câmp obligatioriu'),
  sbpTwoTransportToClinic: Yup.string().required('Câmp obligatioriu'),
  sbpTwoMedicalAirTransportNeeds: Yup.string().required('Câmp obligatioriu'),
  sbpTwoMedicalAirTransportNotes: Yup.string().required('Câmp obligatioriu'),
  sbpTwoPatientLocation: Yup.string().required('Câmp obligatioriu'),

  // sbpThreePatientFirstAndLastName: Yup.string().required('Câmp obligatioriu'),
  // sbpThreePatientAge: Yup.string().required('Câmp obligatioriu'),
  // sbpThreePatientDiagnosis: Yup.string().required('Câmp obligatioriu'),

  // sbpThreeDiagnoses: Yup.array().of(
  //   Yup.object().shape({
  //     patientDiagnosis: Yup.string().required('Câmp obligatioriu'),
  //   }),
  // ),
  sbpThreePatientMedicalDocuments: Yup.mixed().test(
    'fileRequired',
    'Please upload at least one file',
    (value) => {
      // Check if value is a FileList and it contains at least one file
      return value?.length > 0;
      // Validation fails
    },
  ),
  sbpThreePatientHistory: Yup.string().required('Câmp obligatioriu'),
  sbpThreePatientTreatmentInRomania: Yup.string().required('Câmp obligatioriu'),
  sbpThreeClinicContact: Yup.string().required('Câmp obligatioriu'),
  sbpThreeDepartureAndDestinationLand:
    Yup.string().required('Câmp obligatioriu'),
  sbpThreeClinicAcceptance: Yup.string().required('Câmp obligatioriu'),
  sbpThreeLandTransportAttendant: Yup.string().required('Câmp obligatioriu'),
  sbpThreePatientTravelDocument: Yup.string().required('Câmp obligatioriu'),
  sbpThreePatientAttendantTravelDocument:
    Yup.string().required('Câmp obligatioriu'),
  sbpThreeTransportToClinic: Yup.string().required('Câmp obligatioriu'),
  sbpThreeMedicalLandTransportNotes: Yup.string().required('Câmp obligatioriu'),
  sbpThreePatientLocation: Yup.string().required('Câmp obligatioriu'),

  patientHospital: Yup.string().required('Câmp obligatioriu'),
  hospitalSection: Yup.string().required('Câmp obligatioriu'),
  patientDoctor: Yup.string().required('Câmp obligatioriu'),
  doctorContact: Yup.string().required('Câmp obligatioriu'),
  hospitalizationDate: Yup.string().required('Câmp obligatioriu'),

  dataUsageAgreement: Yup.string().required('Câmp obligatioriu'),
});
const AddCase = () => {
  const {
    watch,
    register,
    // handleSubmit,
    formState: { errors },
    setValue,
    setError,
    getValues,
    trigger,
    control,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      diagnoses: [{ patientDiagnosis: '' }],
      // sbpTwoDiagnoses: [{ patientDiagnosis: '' }],
      // sbpThreeDiagnoses: [{ patientDiagnosis: '' }],
    },
  });

  const navigate = useNavigate();

  const [formSent, setFormSent] = useState(false);
  const [dirtyFields, setDirtyFields] = useState({});

  // const [addCase, { isLoading, error }] = useAddCaseMutation();

  const [addRequestCase, { isLoading, error }] = useAddRequestCaseMutation();

  useHandleErrors(error);

  const onSubmit = async (e) => {
    e.preventDefault();
    let isDynamicFormValid = true;
    const allData = getValues();
    const isFormValid = await trigger(stepFields[1][1]);
    //de modificat
    for (let index = 0; index < values?.diagnoses?.length; index++) {
      const isFieldValid = await trigger(`diagnoses.${index}.patientDiagnosis`);
      // Update the overall form validity based on the validation result for the current field
      isDynamicFormValid = isDynamicFormValid && isFieldValid;
    }
    if (isFormValid && isDynamicFormValid) {
      try {
        const res = await addRequestCase(allData).unwrap();
        setFormSent(true);
        // navigate('https://www.asociatia-blondie.ro/');
      } catch (e) {
        console.error(e);
      }
    }
  };
  const values = getValues();

  return (
    <>
      {isLoading && <Spinner />}
      {/* <BasicLayout style={{ padding: 'unset', paddingTop: 'unset' }}>
        <MDBox
          variant={'contained'}
          bgColor={'white'}
          minWidth='21.875rem'
          maxWidth='100%'
          shadow='md'
          borderRadius='md'
          p={1}
          sx={{
            backgroundColor: ({ palette }) => palette.white.main,
          }}
        >
          <section>
            <h3>Contact</h3>
          </section>
          <section>
            {formSent ? (
              <h3>Cerere trimisă cu succes!</h3>
            ) : (
              <form className='add-case__form' onSubmit={onSubmit}>
                <AddCaseContent
                  currentStep={{ step: 1 }}
                  register={register}
                  errors={errors}
                  trigger={trigger}
                  setValue={setValue}
                  watch={watch}
                  control={control}
                />
                <div className='add-case__footer'>
                  <MDButton type='submit'>Submit</MDButton>
                </div>
              </form>
            )}
          </section>
        </MDBox>
      </BasicLayout> */}

      <BasicLayout wideForm>
        <Card
          style={{
            marginTop: '3rem',
            marginBottom: '3rem',
            overflowX: 'visible',
          }}
        >
          <MDBox
            variant='gradient'
            bgColor='info'
            borderRadius='lg'
            coloredShadow='info'
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign='center'
          >
            <MDTypography variant='h4' fontWeight='medium' color='white' mt={1}>
              Contact
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            {formSent ? (
              <h3 className='success--message'>Cerere trimisă cu succes!</h3>
            ) : (
              <MDBox
                component='form'
                role='form'
                onSubmit={onSubmit}
                className='add-case__form'
              >
                <AddCaseContent
                  currentStep={{ step: 1 }}
                  register={register}
                  errors={errors}
                  trigger={trigger}
                  setValue={setValue}
                  watch={watch}
                  control={control}
                  setDirtyFields={setDirtyFields}
                />

                <MDBox display='flex' alignItems='center' ml={-1}></MDBox>
                <MDBox mt={4} mb={1}>
                  <MDButton
                    type='submit'
                    variant='gradient'
                    color='info'
                    fullWidth
                  >
                    Trimite cererea
                  </MDButton>
                </MDBox>
              </MDBox>
            )}
          </MDBox>
        </Card>
      </BasicLayout>
    </>
  );
};

export default AddCase;
