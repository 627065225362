import React, { useEffect } from 'react';
import { useFieldArray } from 'react-hook-form';
import Input from '../../input/index.jsx';
import MDButton from '../../MDButton';

import InputFile from '../../input-file/index.jsx';
import {
  clinicAcceptanceFields,
  patientLocationFields,
  patientRelationship,
  patientRequirement,
  sbpTwoPatientTreatmentInRomaniaFields,
  sbpTwoAirTransportAttendantFields,
  sbpTwoMedicalAirTransportNeedsFields,
  sbpTwoMedicalAirTransportNotesFields,
  sbpTwoPatientAttendantTravelDocumentFields,
  sbpTwoPatientLocationFields,
  sbpTwoPatientTravelDocumentFields,
  sbpTwoTransportToClinicFields,
} from '../../../constants/index.js';
import RadioButtonsList from '../../radio-buttons-list/index.jsx';

const CaseSubstepTwo = ({
  register,
  errors,
  trigger,
  setValue,
  setDirtyFields,
  watch,
  substep,
  handleFileUpload,
  handleRemoveFile,
  uploadedFiles,
  // control,
}) => {
  // const { fields, append, remove } = useFieldArray({
  //   control,
  //   name: 'sbpTwoDiagnoses',
  // });

  const sbpTwoPatientTreatmentInRomania = watch(
    'sbpTwoPatientTreatmentInRomania',
  );
  const sbpTwoClinicAcceptance = watch('sbpTwoClinicAcceptance');
  const sbpTwoAirTransportAttendant = watch('sbpTwoAirTransportAttendant');
  const sbpTwoPatientTravelDocument = watch('sbpTwoPatientTravelDocument');
  const sbpTwoPatientAttendantTravelDocument = watch(
    'sbpTwoPatientAttendantTravelDocument',
  );
  const sbpTwoTransportToClinic = watch('sbpTwoTransportToClinic');
  const sbpTwoMedicalAirTransportNeeds = watch(
    'sbpTwoMedicalAirTransportNeeds',
  );
  const sbpTwoMedicalAirTransportNotes = watch(
    'sbpTwoMedicalAirTransportNotes',
  );
  const sbpTwoPatientLocation = watch('sbpTwoPatientLocation');

  const patientLocation = watch('patientLocation');
  const patientLocationCustomInput = watch('patientLocationCustomInput', '');
  const sbpTwoTransportToClinicCustomInput = watch(
    'sbpTwoTransportToClinicCustomInput',
    '',
  );
  const sbpTwoAirTransportAttendantCustomInput = watch(
    'sbpTwoAirTransportAttendantCustomInput',
    '',
  );
  const sbpTwoMedicalAirTransportNeedsCustomInput = watch(
    'sbpTwoMedicalAirTransportNeedsCustomInput',
    '',
  );
  const sbpTwoMedicalAirTransportNotesCustomInput = watch(
    'sbpTwoMedicalAirTransportNotesCustomInput',
    '',
  );
  const sbpTwoPatientLocationCustomInput = watch(
    'sbpTwoPatientLocationCustomInput',
    '',
  );

  const onChange = (e) => {
    setDirtyFields((prevState) => ({
      ...prevState,
      [e.target.name]: true,
    }));
    setValue(e.target.name, e.target.value);
    trigger(e.target.name);

    if (e.target.name === 'patientLocationCustomInput') {
      // console.log('in useffect');
      setValue('patientLocation', 'other');
      setDirtyFields((prevState) => ({
        ...prevState,
        ['patientLocation']: true,
      }));
    }
    if (e.target.name === 'sbpTwoAirTransportAttendantCustomInput') {
      // console.log('in useffect');
      setValue('sbpTwoAirTransportAttendant', 'other');
      setDirtyFields((prevState) => ({
        ...prevState,
        ['sbpTwoAirTransportAttendant']: true,
      }));
    }
    if (e.target.name === 'sbpTwoTransportToClinicCustomInput') {
      // console.log('in useffect');
      setValue('sbpTwoTransportToClinic', 'other');
      setDirtyFields((prevState) => ({
        ...prevState,
        ['sbpTwoTransportToClinic']: true,
      }));
    }
    if (e.target.name === 'sbpTwoMedicalAirTransportNeedsCustomInput') {
      // console.log('in useffect');
      setValue('sbpTwoMedicalAirTransportNeeds', 'other');
      setDirtyFields((prevState) => ({
        ...prevState,
        ['sbpTwoMedicalAirTransportNeeds']: true,
      }));
    }
    if (e.target.name === 'sbpTwoMedicalAirTransportNotesCustomInput') {
      // console.log('in useffect');
      setValue('sbpTwoMedicalAirTransportNotes', 'other');
      setDirtyFields((prevState) => ({
        ...prevState,
        ['sbpTwoMedicalAirTransportNotes']: true,
      }));
    }
    if (e.target.name === 'sbpTwoPatientLocationCustomInput') {
      // console.log('in useffect');
      setValue('sbpTwoPatientLocation', 'other');
      setDirtyFields((prevState) => ({
        ...prevState,
        ['sbpTwoPatientLocation']: true,
      }));
    }
  };

  const onInputChange = (e) => {
    trigger(e.target.name);
    setDirtyFields((prevState) => ({
      ...prevState,
      [e.target.name]: true,
    }));
    // setValue('radioGroup-customInput', value);
  };

  return (
    <>
      <InputFile
        id='sbpTwoPatientMedicalDocuments'
        name='sbpTwoPatientMedicalDocuments'
        label='Vă rugăm să atașați documentele medicale:'
        optionalText='Ex: scrisoare medicală, analize de sânge, bilet de externare, ecografii, CT, RMN, etc.'
        handleUploadedFile={(files) => {
          handleFileUpload('sbpTwoPatientMedicalDocuments', files);
        }}
        handleRemoveFile={handleRemoveFile}
        onChange={(event) => {
          // const handleChange = (event) => {
          handleFileUpload('sbpTwoPatientMedicalDocuments', event.target.files);
          // };
          // trigger('sbpTwoPatientMedicalDocuments');
        }}
        uploadedFiles={uploadedFiles}
        error={errors.sbpTwoPatientMedicalDocuments}
        multiple={true}
        message='Files'
      />

      <Input
        // placeholder='Nume si prenume'
        type='text'
        name='sbpTwoPatientHistory'
        label='Istoricul pacientului pe scurt'
        optionalText='Ex: Cum a debutat afecțiunea, când, tratamente și internările precedente, etc.'
        error={errors.sbpTwoPatientHistory}
        {...register('sbpTwoPatientHistory', {
          onChange: (e) => onInputChange(e),
        })}
      />

      <RadioButtonsList
        register={register}
        radioGroupValue={sbpTwoPatientTreatmentInRomania}
        onChange={onChange}
        error={
          errors.sbpTwoPatientTreatmentInRomania &&
          errors.sbpTwoPatientTreatmentInRomania.message
        }
        name='sbpTwoPatientTreatmentInRomania'
        className='add-case__patient-relationship__section'
        title='Din informațiile pe care le dețineți la momentul actual, tratamentul se poate efectua pe teritoriul României?'
        list={sbpTwoPatientTreatmentInRomaniaFields}
      />

      <RadioButtonsList
        register={register}
        radioGroupValue={sbpTwoClinicAcceptance}
        onChange={onChange}
        error={
          errors.sbpTwoClinicAcceptance && errors.sbpTwoClinicAcceptance.message
        }
        name='sbpTwoClinicAcceptance'
        className='add-case__patient-relationship__section'
        title='Aveți acceptul de primire al unei clinici din străinătate?'
        list={clinicAcceptanceFields}
      />

      <Input
        // placeholder='Nume si prenume'
        type='date'
        name='sbpTwoClinicAppointment'
        label='Data la care sunteți așteptați de către clinica din străinătate'
        error={errors.sbpTwoClinicAppointment}
        {...register('sbpTwoClinicAppointment', {
          onChange: (e) => onInputChange(e),
        })}
      />

      <Input
        // placeholder='Nume si prenume'
        type='text'
        name='sbpTwoClinicContact'
        label='Aveți un contact al unui reprezentat al clinicii din străinătate?'
        error={errors.sbpTwoClinicContact}
        {...register('sbpTwoClinicContact', {
          onChange: (e) => onInputChange(e),
        })}
      />

      <Input
        // placeholder='Nume si prenume'
        type='text'
        name='sbpTwoDepartureAndDestinationAerial'
        label='Locul de plecare și locul de destinație al transportului aerian medical'
        error={errors.sbpTwoDepartureAndDestinationAerial}
        {...register('sbpTwoDepartureAndDestinationAerial', {
          onChange: (e) => onInputChange(e),
        })}
      />

      <RadioButtonsList
        register={register}
        radioGroupValue={sbpTwoAirTransportAttendant}
        customInputValue={sbpTwoAirTransportAttendantCustomInput}
        onChange={onChange}
        error={
          (errors.sbpTwoAirTransportAttendant &&
            errors.sbpTwoAirTransportAttendant.message) ||
          (sbpTwoAirTransportAttendant === 'other' &&
            sbpTwoAirTransportAttendantCustomInput === '' &&
            'Required')
        }
        name='sbpTwoAirTransportAttendant'
        className='add-case__location__section'
        title='Cine va însoți pacientul pe durata transportului aerian medical?'
        list={sbpTwoAirTransportAttendantFields}
      />

      <RadioButtonsList
        register={register}
        radioGroupValue={sbpTwoPatientTravelDocument}
        onChange={onChange}
        error={
          errors.sbpTwoPatientTravelDocument &&
          errors.sbpTwoPatientTravelDocument.message
        }
        name='sbpTwoPatientTravelDocument'
        className='add-case__location__section'
        title='Pacientul deține următoarele documente de călătorie'
        list={sbpTwoPatientTravelDocumentFields}
      />

      <RadioButtonsList
        register={register}
        radioGroupValue={sbpTwoPatientAttendantTravelDocument}
        onChange={onChange}
        error={
          errors.sbpTwoPatientAttendantTravelDocument &&
          errors.sbpTwoPatientAttendantTravelDocument.message
        }
        name='sbpTwoPatientAttendantTravelDocument'
        className='add-case__location__section'
        title='Însoțitorul pacientului deține următoarele documente de călătorie'
        list={sbpTwoPatientAttendantTravelDocumentFields}
      />

      <RadioButtonsList
        register={register}
        radioGroupValue={sbpTwoTransportToClinic}
        customInputValue={sbpTwoTransportToClinicCustomInput}
        onChange={onChange}
        error={
          (errors.sbpTwoTransportToClinic &&
            errors.sbpTwoTransportToClinic.message) ||
          (sbpTwoTransportToClinic === 'other' &&
            sbpTwoTransportToClinicCustomInput === '' &&
            'Required')
        }
        name='sbpTwoTransportToClinic'
        className='add-case__location__section'
        title='Transferul către o altă clinică din străinătate se face în baza'
        list={sbpTwoTransportToClinicFields}
      />

      <RadioButtonsList
        register={register}
        radioGroupValue={sbpTwoMedicalAirTransportNeeds}
        customInputValue={sbpTwoMedicalAirTransportNeedsCustomInput}
        onChange={onChange}
        error={
          (errors.sbpTwoMedicalAirTransportNeeds &&
            errors.sbpTwoMedicalAirTransportNeeds.message) ||
          (sbpTwoMedicalAirTransportNeeds === 'other' &&
            sbpTwoMedicalAirTransportNeedsCustomInput === '' &&
            'Required')
        }
        name='sbpTwoMedicalAirTransportNeeds'
        className='add-case__location__section'
        title='Ce alte nevoi mai aveți referitoare la transportul aerian medical?'
        list={sbpTwoMedicalAirTransportNeedsFields}
      />

      <RadioButtonsList
        register={register}
        radioGroupValue={sbpTwoMedicalAirTransportNotes}
        customInputValue={sbpTwoMedicalAirTransportNotesCustomInput}
        onChange={onChange}
        error={
          (errors.sbpTwoMedicalAirTransportNotes &&
            errors.sbpTwoMedicalAirTransportNotes.message) ||
          (sbpTwoMedicalAirTransportNotes === 'other' &&
            sbpTwoMedicalAirTransportNotesCustomInput === '' &&
            'Required')
        }
        name='sbpTwoMedicalAirTransportNotes'
        className='add-case__location__section'
        title='Aveți și alte mențiuni în legătură cu transportul aerian medical?'
        list={sbpTwoMedicalAirTransportNotesFields}
      />

      <RadioButtonsList
        register={register}
        radioGroupValue={sbpTwoPatientLocation}
        customInputValue={sbpTwoPatientLocationCustomInput}
        onChange={onChange}
        error={
          (errors.sbpTwoPatientLocation &&
            errors.sbpTwoPatientLocation.message) ||
          (sbpTwoPatientLocation === 'other' &&
            sbpTwoPatientLocationCustomInput === '' &&
            'Required')
        }
        name='sbpTwoPatientLocation'
        className='add-case__location__section'
        title='Unde se află în acest moment pacientul?'
        list={sbpTwoPatientLocationFields}
      />
    </>
  );
};

export default CaseSubstepTwo;
