import moment from 'moment';

export const formatDate = (date) => {
  if (date) {
    return moment(date).format('DD/MM/YYYY');
  }
  return null;
};

export const formatForDatePicker = (date) => {
  // Assuming the input date is in the format 'DD/MM/YYYY'
  const parsedDate = moment(date, 'DD/MM/YYYY');

  if (!parsedDate.isValid()) {
    // Handle invalid date
    console.error('Invalid date:', date);
    return null; // or some default value
  }

  return parsedDate.format('YYYY-MM-DD');
};
