import React, { useState } from 'react';
import CaseStepOne from '../case-step-one/index.jsx';
import CaseStepTwo from '../case-step-two/index.jsx';
import CaseStepThree from '../case-step-three/index.jsx';
import CaseStepFour from '../case-step-four/index.jsx';

const AddCaseContent = ({
  currentStep,
  register,
  errors,
  trigger,
  setValue,
  setDirtyFields,
  watch,
  handleFileUpload,
  handleRemoveFile,
  uploadedFiles,
  control,
}) => {
  const steps = {
    1: (
      <CaseStepOne
        register={register}
        errors={errors}
        trigger={trigger}
        setValue={setValue}
        setDirtyFields={setDirtyFields}
        watch={watch}
        control={control}
      />
    ),
    2: (
      <CaseStepTwo
        register={register}
        errors={errors}
        trigger={trigger}
        setValue={setValue}
        setDirtyFields={setDirtyFields}
        watch={watch}
        substep={currentStep.substep}
        handleFileUpload={handleFileUpload}
        handleRemoveFile={handleRemoveFile}
        uploadedFiles={uploadedFiles}
        // control={control}
      />
    ),
    3: (
      <CaseStepThree
        register={register}
        errors={errors}
        trigger={trigger}
        setValue={setValue}
        setDirtyFields={setDirtyFields}
        watch={watch}
        substep={currentStep.substep}
        handleFileUpload={handleFileUpload}
        handleRemoveFile={handleRemoveFile}
        uploadedFiles={uploadedFiles}
        control={control}
      />
    ),
    4: (
      <CaseStepFour
        register={register}
        errors={errors}
        setValue={setValue}
        setDirtyFields={setDirtyFields}
        watch={watch}
      />
    ),
  };

  return <>{steps[currentStep.step]}</>;
};

export default AddCaseContent;
