import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
  STYLES,
  // VARIANT, SIZE
} from '../../constants';
import './style.scss';

/**
 * @Component Button component.
 */
const Button = ({
  children,
  className = '',
  // variant = VARIANT.TEXT,
  type = 'button',
  onClick = () => {},
  style = STYLES.TRANSPARENT,
  // size = SIZE.SMALL,
  disabled = false,
  tabIndex = 0,
  width = '',
  ...rest
}) => {
  return (
    <button
      tabIndex={tabIndex}
      className={cx(
        'btn',
        className,
        style,
        // variant, size,
        width,
      )}
      onClick={onClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onClick();
        }
      }}
      type={type}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  variant: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  tabIndex: PropTypes.number,
  width: PropTypes.string,
};

export default Button;
