import { PROJECT_URL } from '../constants/index.js';

class TokenHandler {
  static handleExpiredToken() {
    localStorage.removeItem('token');
    window.location.href = PROJECT_URL;
  }
}

export default TokenHandler;
