import api from './api.js';

const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    signup: builder.mutation({
      query: (body) => ({ url: '/auth/signup', method: 'POST', body }),
      providesTags: ['user'],
    }),
    login: builder.mutation({
      query: (body) => ({ url: '/auth/login', method: 'POST', body }),
      providesTags: ['user'],
      transformResponse: (res) => res.data,
    }),
  }),
});

export default userApi;

export const { useSignupMutation, useLoginMutation } = userApi;
