import React from 'react';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Svg,
  Path,
  Image,
} from '@react-pdf/renderer';

import blondieLogo from '../../assets/images/blondie-logo.png';
import icaminLogo from '../../assets/images/icamin114x114.png';
import {
  patientRepresentativeDocumentTypeFields,
  patientRequirement,
} from '../../constants/index.js';
import fontRegular from '../../assets/fonts/AbhayaLibre-Regular.ttf';
import fontBold from '../../assets/fonts/AbhayaLibre-Regular.ttf';
import moment from 'moment';

// Font.register({
//   family: 'CustomFont',
//   // format: 'truetype',

//   src: fontRegular,
// });

// Font.register({
//   family: 'CustomFontBold',
//   // format: 'truetype',

//   src: fontBold,
//   fontWeight: 'bold', // Specify the font weight here

// });

Font.register({
  family: 'Open Sans',
  fonts: [
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf',
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf',
      fontWeight: 600,
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 64,
  },
  title: {
    fontSize: 12,
    marginBottom: 16,
    textAlign: 'center',
    // fontFamily: 'CustomFontBold',
    // fontWeight: 'bold',

    fontFamily: 'Open Sans',
    // fontSize: 20,
    fontWeight: 600,
  },
  subTitle: {
    fontSize: 12,
    marginBottom: 12,
    fontFamily: 'Open Sans',
    // fontSize: 20,
    fontWeight: 600,
  },

  section: {
    marginBottom: 10,
  },
  sectionTextParagraph: {
    fontSize: 12,
    marginBottom: 5,
    textIndent: 16,
    fontFamily: 'CustomFont',
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  sectionText: {
    fontSize: 12,
    marginBottom: 8,
    fontFamily: 'CustomFont',
  },
  firstText: {
    textAlign: 'center',
  },
  checkboxLabel: {
    fontSize: 12,
    marginLeft: 10,
    fontFamily: 'CustomFont',
  },

  data: {
    fontSize: 12,
    fontFamily: 'CustomFont',
  },
  checkboxContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  checkbox: {
    width: 12,
    height: 12,
    marginRight: 5,
    justifyContent: 'center',
  },
  labelText: {
    fontFamily: 'CustomFont',
    fontSize: 12,
  },
  checked: {
    backgroundColor: '#007bff',
    color: '#fff',
  },
  headerImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 155.52,
    height: 57.6,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },

  footerImage: {
    position: 'absolute',
    bottom: 20,
    left: 20,
  },
});

const Checkbox = ({ checked }) => {
  return (
    <View style={styles.checkbox}>
      <Svg width={12} height={12} viewBox='0 0 12 12'>
        <Path d='M1 1H11V11H1z' stroke='black' strokeWidth='1' fill='none' />
        {checked && (
          <Path
            d='M1.5 6L4 8.5L10.5 2'
            stroke='black'
            strokeWidth='1'
            fill='none'
            strokeLinecap='round'
          />
        )}
      </Svg>
    </View>
  );
};

const GDPRPhoto = ({
  patientRepresentative = '............................................................',
  patientFirstAndLastName = '............................................................',
  patientAge = '........',
  patientPIN = '.....................................',

  patientRelationship = '................',
  patientRepresentativePIN = '......................................................',
  patientRepresentativeAge = '................................',
  patientRepresentativeEmail = '................................',
  patientRepresentativePhoneNumber = '................................',
  patientRepresentativeAddressCityBorn = '.......................................',
  patientRepresentativeAddressCity = '.......................................',
  patientRepresentativeAddressCitySector = '....',
  patientRepresentativeAddressStreet = '........................................',
  patientRepresentativeAddressStreetNo = '............',
  patientRepresentativeAddressStreetBlock = '.............',
  patientRepresentativeAddressBlockEntrance = '..............',
  patientRepresentativeAddressBlockFloor = '....',
  patientRepresentativeAddressBlockApartment = '................',
  patientRepresentativeAddressCounty = '......................',
  patientRepresentativeSN = '.........................',
  patientRepresentativeSNNo = '...........................',
  patientRepresentativeDocumentIssuedBy = '.......................',
  patientRepresentativeDocumentIssueDate = '..........................',
  patientRepresentativeDocumentType = 'CI/BI/pașaport',
}) => {
  const documentType =
    patientRepresentativeDocumentType ===
    patientRepresentativeDocumentTypeFields[0].value
      ? patientRepresentativeDocumentTypeFields[0].name
      : patientRepresentativeDocumentType ===
        patientRepresentativeDocumentTypeFields[1].value
      ? patientRepresentativeDocumentTypeFields[1].name
      : patientRepresentativeDocumentType;

  // console.log('firstAndLastName', firstAndLastName);
  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <Image
          src={blondieLogo}
          style={[styles.header, styles.headerImage]}
          fixed
        />

        <View style={styles.section}>
          <Text style={styles.title} font='CustomFontBold'>
            ACORD {'\n'}
            PENTRU FOLOSIREA FOTOGRAFIILOR / ÎNREGISTRĂRILOR VIDEO ÎN CADRUL{' '}
            {'\n'}
            ACTIVITĂȚILOR {'\n'}
            ASOCIAȚIEI BLONDIE – ÎMPARTE PENTRU ALTĂ PARTE
          </Text>
          <Text style={styles.title}></Text>
          <Text style={[styles.sectionText, styles.subTitle]}>
            Art. I Părțile
          </Text>
          <Text style={styles.sectionText}>
            1.1{' '}
            <Text style={[styles.sectionText, styles.subTitle]}>
              ASOCIAȚIA BLONDIE - ÎMPARTE PENTRU ALTĂ PARTE{' '}
            </Text>
            cu sediul în București, sector 4, str. codul de înregistrare fiscal
            40914732, înregistrată Registrul Asociațiilor și entităților de cult
            ONG cu nr.2141266/30.07.2019, reprezentată de doamna Veronica Manea,
            având funcția de președinte, pe de o parte
            {'\n'}și
          </Text>
          <Text style={styles.sectionText}>
            <Text style={[styles.sectionText, styles.subTitle]}>
              1.2. Dna./Dl.
            </Text>{' '}
            {patientRepresentative}, domiciliat/ă în{' '}
            {patientRepresentativeAddressCity}, sect.{' '}
            {patientRepresentativeAddressCitySector}, str.{' '}
            {patientRepresentativeAddressStreet}, nr.{' '}
            {patientRepresentativeAddressStreetNo}, bl.{' '}
            {patientRepresentativeAddressStreetBlock}, sc.{' '}
            {patientRepresentativeAddressBlockEntrance}, et.{' '}
            {patientRepresentativeAddressBlockFloor}, ap.{' '}
            {patientRepresentativeAddressBlockApartment}, identificat/a cu{' '}
            {documentType} seria {patientRepresentativeSN}. nr.
            {patientRepresentativeSNNo}, eliberat/ă de{' '}
            {patientRepresentativeDocumentIssuedBy}, la data de{' '}
            {patientRepresentativeDocumentIssueDate}, CNP{' '}
            {patientRepresentativePIN}, în calitate de beneficiar și{' '}
            {patientRelationship}
          </Text>
          <Text style={styles.sectionText}>
            <Text style={[styles.sectionText, styles.subTitle]}>
              1.3. Copilul
            </Text>{' '}
            {patientFirstAndLastName}, născut la data de {patientAge}, CNP{' '}
            {patientPIN}, în calitate de (fiu, fiica etc.) ..............., pe
            de altă parte,{'\n'}
            În temeiul dispozițiilor art. 73 din Noul Cod civil, au înțeles să
            încheie prezentul acord cu respectarea următoarelor clauze:
          </Text>
          <Text style={[styles.sectionText, styles.subTitle]}>
            Art. II Obiectul acordului
          </Text>
          <Text style={styles.sectionText}>
            <Text style={[styles.sectionText, styles.subTitle]}>
              2.1. Subsemnata/Subsemnatul
            </Text>{' '}
            {patientRepresentative}, în baza dreptului meu constituțional de a
            dispune asupra numelui, vocii și imaginii mele, declar ca sunt acord
            ca{' '}
            <Text style={[styles.sectionText, styles.subTitle]}>
              ASOCIAȚIA BLONDIE - ÎMPARTE PENTRU ALTĂ PARTE
            </Text>
            , reprezentanții, prepușii și colaboratorii săi precum și persoanele
            sau societățile care acționează cu permisiunea{' '}
            <Text style={[styles.sectionText, styles.subTitle]}>
              ASOCIAȚIA BLONDIE - ÎMPARTE PENTRU ALTĂ PARTE
            </Text>
            , să folosească și să publice portrete sau imagini fotografice care
            mă reprezintă pe mine și /sau copilul meu, precum şi tipăriturile,
            diapozitivele sau informaţiile digitale aferente acestora, în care
            aş putea fi inclus în totalitate sau parţial, sau modificate ca
            formă, sau reproduse sub diverse forme, color sau altfel, făcute
            prin orice mijloc, ca formă de publicitate sau orice altă formă
            legală, în orice format, fotografie, simplu, multiplu, în miscare
            sau imagine video, indiferent de modul în care imaginea va fi
            distribuită (publicată în presă, pliante, pe internet, rețele de
            socializare sau oriunde altundeva). Termenul de imagine este
            considerat că acopera toate atributele personalității (care include
            în special imaginea fixă sau mobilă, portretul, silueta, vocea,
            numele și prenumele sau semnătura acestuia).
          </Text>

          <Text style={styles.sectionText}>
            Dau acest acord cu titlu gratuit fără să am nici o pretenție
            băneasca sau de altă natură în prezent sau în viitor, pentru
            materialele publicate de{' '}
            <Text style={[styles.sectionText, styles.subTitle]}>
              ASOCIAȚIA BLONDIE - ÎMPARTE PENTRU ALTĂ PARTE
            </Text>{' '}
            în considerarea acestei autorizări,
          </Text>

          <Text style={styles.sectionText}>
            2.2.
            <Text style={[styles.sectionText, styles.subTitle]}>
              {' '}
              ASOCIAȚIA BLONDIE - ÎMPARTE PENTRU ALTĂ PARTE
            </Text>{' '}
            are dreptul de a prelucra imaginea și de a o folosi atât în
            intregime, cât și parte din aceasta, în orice mărime și pe orice
            suport, indiferent dacă va obține sau nu venit din acestea. De
            asemenea, fotografia/filmarea va putea fi însoțită de orice legendă,
            comentarii și/sau ilustrații.
            <Text>
              Prezenta autorizație este permisă fără limită în ceea ce privește
              numărul de reproduceri, reprezentări și adaptări realizate și fără
              niciun cost.
            </Text>
            <Text>
              De asemenea, materialele realizate ca urmare a prezentului acord
              vor putea fi folosite oricând, chiar și după data la care
              prezentul document își încetează efectele.
            </Text>
          </Text>

          <Text style={styles.sectionText}>
            Prin prezentul document, declar că renunț la orice drept pe care îl
            am, de a verifica şi aproba modul de utilizare, care ar putea fi
            ales, al imaginilor respective. De asemenea, scutesc{' '}
            <Text style={[styles.sectionText, styles.subTitle]}>
              ASOCIAȚIA BLONDIE - ÎMPARTE PENTRU ALTĂ PARTE
            </Text>{' '}
            şi celelalte părți care acționează - autorizați de către{' '}
            <Text style={[styles.sectionText, styles.subTitle]}>
              ASOCIAȚIA BLONDIE - ÎMPARTE PENTRU ALTĂ PARTE
            </Text>{' '}
            de orice revendicare de plată, asociată cu orice formă de daună, fie
            prevăzută, fie nu, legată de utilizarea corespunzătoare acestor
            imagini.
          </Text>

          <Text style={styles.sectionText}>
            2.4. În utilizarea imaginilor,{' '}
            <Text style={[styles.sectionText, styles.subTitle]}>
              ASOCIAȚIA BLONDIE - ÎMPARTE PENTRU ALTĂ PARTE
            </Text>{' '}
            se obligă să respecte prevederile legale, să nu aducă atingere
            demnității, vieții familiale, intime și private.
          </Text>

          <Text style={[styles.sectionText, styles.subTitle]}>
            Art. III Dreptul de autor
          </Text>

          <Text style={styles.sectionText}>
            3.1. Fotografiile/filmările/desenele/tablourile/schițele sau alte
            asemenea conținând imaginea mea și a copilului meu sunt considerate
            creație și aparțin autorului lor sau{' '}
            <Text style={[styles.sectionText, styles.subTitle]}>
              ASOCIAȚIA BLONDIE - ÎMPARTE PENTRU ALTĂ PARTE
            </Text>{' '}
            dacă sunt făcute de acesta sau dacă i s-au cedat drepturile de către
            fotograf/realizator.
          </Text>
          <Text style={styles.sectionText}>
            3.2. Toate materialele realizate și/sau publicate în cadrul
            prezentei autorizații vor rămâne în proprietatea exclusivă a{' '}
            <Text style={[styles.sectionText, styles.subTitle]}>
              ASOCIAȚIA BLONDIE - ÎMPARTE PENTRU ALTĂ PARTE
            </Text>
            .
          </Text>
          <Text style={[styles.sectionText, styles.subTitle]}>
            Art. IV Teritorialitate
          </Text>
          <Text style={styles.sectionText}>
            4.1. Prezentul acord este valabil pe teritoriul României, dar și în
            orice altă țară în care acționează{' '}
            <Text style={[styles.sectionText, styles.subTitle]}>
              ASOCIAȚIA BLONDIE - ÎMPARTE PENTRU ALTĂ PARTE
            </Text>
            .
          </Text>
          <Text style={[styles.sectionText, styles.subTitle]}>Art. V</Text>
          <Text style={styles.sectionText}>
            5.1. Subsemnata/subsemnatul {patientRepresentative}, mă angajez să
            pastrez cu strictețe confidențialitatea prezentului document precum
            și a tuturor informațiilor, indiferent de natura acestora, obținute
            cu ocazia executării materialelor care fac obiectul prezentei
            autorizații.
          </Text>
          <Text style={styles.sectionText}>
            5.2 Conform Regulamentului (UE) 2016/679 al Parlamentului European
            și al Consiliului din 27 aprilie 2016 privind protecția persoanelor
            fizice în ceea ce privește prelucrarea datelor cu caracter personal
            și privind libera circulație a acestor date și de abrogare a
            Directivei 95/46/CE,{' '}
            <Text style={[styles.sectionText, styles.subTitle]}>
              ASOCIAȚIA BLONDIE - ÎMPARTE PENTRU ALTĂ PARTE
            </Text>{' '}
            se obligă să administreze și să prelucreze în condiții de siguranță,
            transparență și numai în scopurile specificate, datele cu caracter
            personal ale doamnei/domnului și sau copilului său, mai sus
            menționați.
          </Text>
          <Text style={styles.sectionText}>
            5.3. Dacă o stipulare din prezentul document va fi declarată nulă
            sau inaplicabilă printr-o decizie definitivă dată de justiție,
            aceasta va fi considerată nescrisă și toate celelalte clauze vor
            rămâne în vigoare.
          </Text>
          <Text style={styles.sectionText}>
            5.4. Prezentul acord a fost negociat și semnat de ambele părți azi,
            <Text style={styles.data}>
              {moment(new Date().toLocaleDateString()).format('DD/MM/YYYY')}
            </Text>
          </Text>

          <View style={styles.container}>
            <Text style={[styles.sectionText, styles.firstText]}>
              ASOCIAȚIA BLONDIE {'\n'} ÎMPARTE PENTRU ALTĂ PARTE
            </Text>

            <Text style={styles.sectionText}>BENEFICIAR</Text>
          </View>
        </View>
        {/* <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} fixed /> */}

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
        <View style={[styles.footer, styles.footerImage]} fixed>
          <Text style={styles.sectionText}>Document generat cu iCRM.ro</Text>
        </View>
      </Page>
    </Document>
  );
};

export default GDPRPhoto;
