import React from 'react';

import './style.scss';
import Button from '../../components/button/index.jsx';
import { useNavigate } from 'react-router-dom';
import {
  useGetCasesQuery,
  useGetContactCasesQuery,
} from '../../redux/api/case.js';

import useHandleErrors from '../../hooks/useHandleErrors.js';
import { STYLES as STYLE } from '../../constants/index.js';
import ContainerCard from '../../components/container-card/index.jsx';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';

const Cases = () => {
  const navigate = useNavigate();

  const { data, isLoading, error } = useGetCasesQuery();
  useHandleErrors(error);

  // const { data: contactData } = useGetContactCasesQuery();
  // console.log('error', error);
  // useHandleErrors(error);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar position='absolute' />
        <div className='cases__wrapper'>
          <section className='cases__wrapper__title'>
            <h1>Cazuri</h1>
          </section>
          <div className='cases__content'>
            <section className='cases__content__add'>
              <Button style={STYLE.PINK} onClick={() => navigate('/add-case')}>
                Adaugă caz
              </Button>
            </section>
            <section className='cases__content__list'>
              {data?.map((item) => (
                <ContainerCard
                  key={item.id}
                  className='cases__content__list__item'
                  onClick={() => navigate(`/cases/${item.id}`)}
                >
                  <h2 className='cases__content__list__item__title'>
                    Caz #{item.id}
                  </h2>
                  <p>{item.firstAndLastName}</p>
                </ContainerCard>
              ))}
            </section>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};

export default Cases;
