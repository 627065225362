import React, { forwardRef, useState } from 'react';
import cx from 'classnames';

import RadioButton from '../radio-button/index.jsx';
import Input from '../input/index.jsx';

import './style.scss';

const RadioButtonsList = ({
  // list = [],
  name = '',
  className = '',
  // errors = {},
  // register,
  // ...rest
  register,
  radioGroupValue,
  customInputValue,
  onChange,
  error,
  title = '',
  list = [],
}) => {
  // const [otherInputValue, setOtherInputValue] = useState();
  // const [selectedRadioButton, setSelectedRadioButton] = useState('');
  // const handleChange = (event) => {
  //   console.log('handle radio change');
  //   if (event.target.name === 'other') {
  //     setOtherInputValue(event.target.value);
  //
  //     if (event.target.value.length > 0) {
  //       setSelectedRadioButton('other');
  //     }
  //   } else {
  //     setSelectedRadioButton(event.target.value);
  //   }
  // };

  return (
    <div className={cx('radio-buttons-list', className)}>
      <p>{title}</p>
      <ul>
        {list?.length &&
          list?.map((item, index) => (
            // item?.other ? (
            <li key={item.id}>
              <RadioButton
                name={name}
                key={index}
                id={item.id}
                value={item.value}
                label={item.name}
                checked={radioGroupValue === item.value}
                onChange={onChange}
                inputValue={customInputValue}
                register={register}
              />
            </li>
          ))}
      </ul>
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default RadioButtonsList;
