import React, { useState } from 'react';

// react-router-dom components
import { Link, useNavigate } from 'react-router-dom';

// @mui material components
import Card from '@mui/material/Card';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import MuiLink from '@mui/material/Link';

// @mui icons
import FacebookIcon from '@mui/icons-material/Facebook';
import GitHubIcon from '@mui/icons-material/GitHub';
import GoogleIcon from '@mui/icons-material/Google';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';

// Authentication layout components
import BasicLayout from 'layouts/authentication/components/BasicLayout';

// Images
import bgImage from 'assets/images/bg-sign-in-basic.jpeg';
import Input from '../../../components/input';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { useLoginMutation } from '../../../redux/api/user';
import Spinner from '../../../components/spinner';

const schema = Yup.object().shape({
  email_or_phone: Yup.string()
    .matches(
      /^(?:\+40|0)[1-9][0-9]{8}$|^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      'Please enter a valid email or Romanian phone number',
    )
    .required('This field is required'),
  password: Yup.string().required('Password is required'),
});

const validateEmail = () => {
  return Yup.string().email('Invalid Email');
};

const validatePhone = () => {
  return Yup.string().matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*[\]{}()?"\\,><':;|_~`=+-])[a-zA-Z\d!@#$%^&*[\]{}()?"\\,><':;|_~`=+-]{8,99}$/,
  );
};

function Basic() {
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [triggerLogin, { isLoading }] = useLoginMutation();

  const onSubmit = async (data) => {
    try {
      const param = data.email_or_phone.includes('@')
        ? { email: data.email_or_phone }
        : { phone: data.email_or_phone };
      const loginData = await triggerLogin({
        ...param,
        password: data.password,
      }).unwrap();
      localStorage.setItem('token', loginData);
      navigate('/');
    } catch (error) {
      setError('email', {
        type: 'server side',
      });
      setError('password', {
        type: 'server side',
        message: error.data.message,
      });
    }
  };

  return (
    <>
      {isLoading && <Spinner />}

      <BasicLayout>
        <Card>
          <MDBox
            variant='gradient'
            bgColor='info'
            borderRadius='lg'
            coloredShadow='info'
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign='center'
          >
            <MDTypography variant='h4' fontWeight='medium' color='white' mt={1}>
              Sign in
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox
              component='form'
              role='form'
              onSubmit={handleSubmit(onSubmit)}
            >
              <MDBox mb={2}>
                {/*<MDInput type='email' label='Email' fullWidth />*/}
                <Input
                  // onFocus={resetLogInErrors}
                  placeholder='Email/phone'
                  type='text'
                  name='email_or_phone'
                  label='Email/phone'
                  error={errors.email_or_phone}
                  {...register('email_or_phone')}
                />
              </MDBox>
              <MDBox mb={2}>
                <Input
                  // onFocus={resetLogInErrors}
                  placeholder='Password'
                  type='password'
                  name='password'
                  label='Password'
                  error={errors.password}
                  {...register('password')}
                />
              </MDBox>
              <MDBox display='flex' alignItems='center' ml={-1}></MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton
                  type='submit'
                  variant='gradient'
                  color='info'
                  fullWidth
                >
                  sign in
                </MDButton>
              </MDBox>
              <MDBox mt={3} mb={1} textAlign='center'>
                <MDTypography variant='button' color='text'>
                  Don&apos;t have an account?{' '}
                  <MDTypography
                    component={Link}
                    to='/sign-up'
                    variant='button'
                    color='info'
                    fontWeight='medium'
                    textGradient
                  >
                    Sign up
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </BasicLayout>
    </>
  );
}

export default Basic;
