import api from './api.js';

const requestCaseApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addRequestCase: builder.mutation({
      query: (body) => {
        const bodyObj = {};
        Object.entries(body).forEach(([key, value]) => {
          if (key.toLowerCase().includes('diagnoses')) {
            bodyObj[key] = JSON.stringify(value);
            //   console.log('1');
            //   formData.append('diagnoses', JSON.stringify(value));
          } else if (body[key] !== '' && body[key] !== null) {
            bodyObj[key] = value;
          }
          // else {
          //   console.log('2');
          //   formData.append(key, value);
          // }
        });

        return {
          url: '/request-case',
          method: 'POST',
          body: bodyObj,
          responseType: 'json',
        };
      },
      invalidatesTags: ['cases'],
    }),
  }),
});

export default requestCaseApi;

export const { useAddRequestCaseMutation } = requestCaseApi;
