import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useNavigate, useLocation } from 'react-router-dom';

import { useForm, useFormContext } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Input from '../../components/input/index.jsx';
import RadioButtonsList from '../../components/radio-buttons-list/index.jsx';
import {
  patientLocationFields,
  patientRelationship,
  patientRequirement,
  sbpThreePatientLocationFields,
  sbpTwoPatientLocationFields,
  stepFields,
  STYLES as STYLE,
} from '../../constants/index.js';
import Button from '../../components/button/index.jsx';

import './style.scss';
import AddCaseContent from '../../components/add-case-content/index.jsx';
import { capitalize, getFormData } from '../../util/form.js';
import {
  useAddCaseMutation,
  useGetCaseQuery,
  useLazyGetCasesQuery,
  useUpdateCaseMutation,
} from '../../redux/api/case.js';
import useHandleErrors from '../../hooks/useHandleErrors.js';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import MDButton from '../../components/MDButton';
import Spinner from '../../components/spinner';
import Grid from '@mui/material/Grid';

const schema = Yup.object({
  firstAndLastName: Yup.string().required('Câmp obligatioriu'),
  email: Yup.string().required('Câmp obligatioriu'),
  patientRelationship: Yup.string().required('Câmp obligatioriu'),
  patientRequirement: Yup.string().required('Câmp obligatioriu'),
  patientRepresentative: Yup.string().required('Câmp obligatioriu'),
  patientRelationshipCustomInput: Yup.string().when('patientRelationship', {
    is: 'other',
    then: (schema) => schema.required('Câmp obligatioriu'),
    otherwise: (schema) => schema.nullable(true),
  }),
  phoneNumber: Yup.string().required('Câmp obligatioriu'),
  patientFirstAndLastName: Yup.string().required('Câmp obligatioriu'),
  patientAge: Yup.string().required('Câmp obligatioriu'),
  // patientDiagnosis: Yup.string().required('Câmp obligatioriu'),
  diagnoses: Yup.array().of(
    Yup.object().shape({
      patientDiagnosis: Yup.string().required('Câmp obligatioriu'),
    }),
  ),
  patientMedicalDocuments: Yup.mixed().test(
    'fileRequired',
    'Încărcați cel puțin un fișier',
    (value) => {
      // Check if value is a FileList and it contains at least one file
      return value?.length > 0;
      // Validation fails
    },
  ),
  patientHistory: Yup.string().required('Câmp obligatioriu'),
  patientTreatmentInRomania: Yup.string().required('Câmp obligatioriu'),
  patientLocation: Yup.string().required('Câmp obligatioriu'),
  // sbpTwoPatientFirstAndLastName: Yup.string().required('Câmp obligatioriu'),
  // sbpTwoPatientAge: Yup.string().required('Câmp obligatioriu'),
  // sbpTwoPatientDiagnosis: Yup.string().required('Câmp obligatioriu'),

  // sbpTwoDiagnoses: Yup.array().of(
  //   Yup.object().shape({
  //     patientDiagnosis: Yup.string().required('Câmp obligatioriu'),
  //   }),
  // ),
  sbpTwoPatientMedicalDocuments: Yup.mixed().test(
    'fileRequired',
    'Please upload at least one file',
    (value) => {
      // Check if value is a FileList and it contains at least one file
      return value?.length > 0;
      // Validation fails
    },
  ),
  sbpTwoPatientHistory: Yup.string().required('Câmp obligatioriu'),
  sbpTwoPatientTreatmentInRomania: Yup.string().required('Câmp obligatioriu'),
  sbpTwoClinicAcceptance: Yup.string().required('Câmp obligatioriu'),
  sbpTwoClinicContact: Yup.string().required('Câmp obligatioriu'),
  sbpTwoDepartureAndDestinationAerial:
    Yup.string().required('Câmp obligatioriu'),
  sbpTwoAirTransportAttendant: Yup.string().required('Câmp obligatioriu'),
  sbpTwoPatientTravelDocument: Yup.string().required('Câmp obligatioriu'),
  sbpTwoPatientAttendantTravelDocument:
    Yup.string().required('Câmp obligatioriu'),
  sbpTwoTransportToClinic: Yup.string().required('Câmp obligatioriu'),
  sbpTwoMedicalAirTransportNeeds: Yup.string().required('Câmp obligatioriu'),
  sbpTwoMedicalAirTransportNotes: Yup.string().required('Câmp obligatioriu'),
  sbpTwoPatientLocation: Yup.string().required('Câmp obligatioriu'),

  // sbpThreePatientFirstAndLastName: Yup.string().required('Câmp obligatioriu'),
  // sbpThreePatientAge: Yup.string().required('Câmp obligatioriu'),
  // sbpThreePatientDiagnosis: Yup.string().required('Câmp obligatioriu'),

  // sbpThreeDiagnoses: Yup.array().of(
  //   Yup.object().shape({
  //     patientDiagnosis: Yup.string().required('Câmp obligatioriu'),
  //   }),
  // ),
  sbpThreePatientMedicalDocuments: Yup.mixed().test(
    'fileRequired',
    'Please upload at least one file',
    (value) => {
      // Check if value is a FileList and it contains at least one file
      return value?.length > 0;
      // Validation fails
    },
  ),
  sbpThreePatientHistory: Yup.string().required('Câmp obligatioriu'),
  sbpThreePatientTreatmentInRomania: Yup.string().required('Câmp obligatioriu'),
  sbpThreeClinicContact: Yup.string().required('Câmp obligatioriu'),
  sbpThreeDepartureAndDestinationLand:
    Yup.string().required('Câmp obligatioriu'),
  sbpThreeClinicAcceptance: Yup.string().required('Câmp obligatioriu'),
  sbpThreeLandTransportAttendant: Yup.string().required('Câmp obligatioriu'),
  sbpThreePatientTravelDocument: Yup.string().required('Câmp obligatioriu'),
  sbpThreePatientAttendantTravelDocument:
    Yup.string().required('Câmp obligatioriu'),
  sbpThreeTransportToClinic: Yup.string().required('Câmp obligatioriu'),
  sbpThreeMedicalLandTransportNotes: Yup.string().required('Câmp obligatioriu'),
  sbpThreePatientLocation: Yup.string().required('Câmp obligatioriu'),

  patientHospital: Yup.string().required('Câmp obligatioriu'),
  hospitalSection: Yup.string().required('Câmp obligatioriu'),
  patientDoctor: Yup.string().required('Câmp obligatioriu'),
  doctorContact: Yup.string().required('Câmp obligatioriu'),
  hospitalizationDate: Yup.string().required('Câmp obligatioriu'),

  dataUsageAgreement: Yup.string().required('Câmp obligatioriu'),
});
const AddCase = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [currentStep, setCurrentStep] = useState({ step: 1, substep: 1 });
  // const uploadedFiles = useRef({});
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [dirtyFields, setDirtyFields] = useState({});
  const prevStep = useRef([]);
  const filesToRemove = useRef([]);

  const [addCase, { isLoading, error }] = useAddCaseMutation();
  const [updateCase, { isLoading: isUpdateLoading, error: updateError }] =
    useUpdateCaseMutation();
  const {
    data: { formattedFormData: lazyCaseData } = {},
    isLoading: isLazyCaseLoading,
    error: lazyCaseError,
  } = useGetCaseQuery(id, { skip: !id });

  const {
    watch,
    register,
    // handleSubmit,
    formState: { errors },
    setValue,
    setError,
    getValues,
    trigger,
    control,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...lazyCaseData,
      diagnoses: [{ patientDiagnosis: '' }],
      // sbpTwoDiagnoses: [{ patientDiagnosis: '' }],
      // sbpThreeDiagnoses: [{ patientDiagnosis: '' }],
    },
  });

  useEffect(() => {
    if (lazyCaseData) {
      Object.keys(lazyCaseData).forEach((key) => {
        if (key.includes('PatientMedicalDocuments')) {
          setUploadedFiles({ [key]: lazyCaseData[key] });
        }
        setValue(key, lazyCaseData[key]);
        // if (key.includes('patientMedicalDocuments')) {
        //   setUploadedFiles({ [key]: lazyCaseData[key] });
        //
        //   const attachmentsArray = [];
        //   lazyCaseData[key].forEach((attachment) => {
        //     attachmentsArray.push({ name: attachment, isDirty: false });
        //   });
        //   if (
        //     lazyCaseData?.patientRequirement === patientRequirement[0].value ||
        //     lazyCaseData?.patientRequirement === patientRequirement[3].value
        //   ) {
        //     setValue('patientMedicalDocuments', attachmentsArray);
        //     setUploadedFiles({ patientMedicalDocuments: attachmentsArray });
        //   }
        //   if (
        //     lazyCaseData?.patientRequirement === patientRequirement[1].value
        //   ) {
        //     setValue('sbpTwoPatientMedicalDocuments', attachmentsArray);
        //     setUploadedFiles({
        //       sbpTwoPatientMedicalDocuments: attachmentsArray,
        //     });
        //   }
        //   if (
        //     lazyCaseData?.patientRequirement === patientRequirement[2].value
        //   ) {
        //     setValue('sbpThreePatientMedicalDocuments', attachmentsArray);
        //     setUploadedFiles({
        //       sbpThreePatientMedicalDocuments: attachmentsArray,
        //     });
        //   }
        // } else if (!key.includes('MedicalDocuments')) {
        //   if (
        //     stepFields[1][1].includes(key) ||
        //     stepFields[3][1].includes(key) ||
        //     stepFields[4][1].includes(key)
        //   ) {
        //     setValue(key, lazyCaseData[key]);
        //   } else if (
        //     lazyCaseData?.patientRequirement === patientRequirement[1].value
        //   ) {
        //     if (
        //       key === 'medicalTransportNeeds' ||
        //       key === 'medicalTransportNeedsCustomInput' ||
        //       key === 'medicalTransportNotes' ||
        //       key === 'medicalTransportNotesCustomInput'
        //     ) {
        //       setValue(
        //         `sbpTwoMedicalAir${capitalize(key).slice(7)}`,
        //         lazyCaseData[key],
        //       );
        //     } else if (capitalize(key).includes('TransportAttendant')) {
        //       setValue(`sbpTwoAir${capitalize(key)}`, lazyCaseData[key]);
        //     } else {
        //       setValue(`sbpTwo${capitalize(key)}`, lazyCaseData[key]);
        //     }
        //   } else if (
        //     lazyCaseData?.patientRequirement === patientRequirement[2].value
        //   ) {
        //     if (
        //       key === 'medicalTransportNeeds' ||
        //       key === 'medicalTransportNeedsCustomInput' ||
        //       key === 'medicalTransportNotes' ||
        //       key === 'medicalTransportNotesCustomInput'
        //     ) {
        //       setValue(
        //         `sbpThreeMedicalLand${capitalize(key).slice(7)}`,
        //         lazyCaseData[key],
        //       );
        //     } else if (capitalize(key).includes('TransportAttendant')) {
        //       setValue(`sbpThreeLand${capitalize(key)}`, lazyCaseData[key]);
        //     } else {
        //       setValue(`sbpThree${capitalize(key)}`, lazyCaseData[key]);
        //     }
        //   }
        // }
      });
    }
  }, [lazyCaseData]);

  const values = getValues();

  useHandleErrors([error, updateError, lazyCaseError]);
  const handleNext = async () => {
    const values = getValues();

    let substep = 1;
    let isLastStep = false;
    let isDynamicFormValid = true;

    if (currentStep.step === 1) {
      if (values?.patientRequirement === patientRequirement[0].value) {
        substep = 1;
      }
      if (values?.patientRequirement === patientRequirement[1].value) {
        substep = 2;
      }
      if (values?.patientRequirement === patientRequirement[2].value) {
        substep = 3;
      }
    } else if (currentStep.step === 2) {
      if (currentStep.substep === 1) {
        if (values?.patientLocation === patientLocationFields[0].value) {
          substep = 1;
        }
        if (values?.patientLocation === patientLocationFields[1].value) {
          isLastStep = true;
        }
        if (values?.patientLocation === patientLocationFields[2].value) {
          isLastStep = true;
        }
        //de modificat
        for (let index = 0; index < values?.diagnoses?.length; index++) {
          const isFieldValid = await trigger(
            `diagnoses.${index}.patientDiagnosis`,
          );
          // Update the overall form validity based on the validation result for the current field
          isDynamicFormValid = isDynamicFormValid && isFieldValid;
        }

        //de modificat
      } else if (currentStep.substep === 2) {
        if (
          values?.sbpTwoPatientLocation === sbpTwoPatientLocationFields[0].value
        ) {
          substep = 1;
        }
        if (
          values?.sbpTwoPatientLocation === sbpTwoPatientLocationFields[1].value
        ) {
          isLastStep = true;
        }
        if (
          values?.sbpTwoPatientLocation === sbpTwoPatientLocationFields[2].value
        ) {
          isLastStep = true;
        }
        //de modificat
        // for (let index = 0; index < values?.sbpTwoDiagnoses?.length; index++) {
        //   const isFieldValid = await trigger(
        //     `sbpTwoDiagnoses.${index}.patientDiagnosis`,
        //   );
        //   console.log('isFieldValidfff', isFieldValid);
        //   // Update the overall form validity based on the validation result for the current field
        //   isDynamicFormValid = isDynamicFormValid && isFieldValid;
        // }

        //de modificat
      } else if (currentStep.substep === 3) {
        if (
          values?.sbpThreePatientLocation ===
          sbpThreePatientLocationFields[0].value
        ) {
          substep = 1;
        }
        if (
          values?.sbpThreePatientLocation ===
          sbpThreePatientLocationFields[1].value
        ) {
          isLastStep = true;
        }
        if (
          values?.sbpThreePatientLocation ===
          sbpThreePatientLocationFields[2].value
        ) {
          isLastStep = true;
        }
        //de modificat
        // for (
        //   let index = 0;
        //   index < values?.sbpThreeDiagnoses?.length;
        //   index++
        // ) {
        //   const isFieldValid = await trigger(
        //     `sbpThreeDiagnoses.${index}.patientDiagnosis`,
        //   );
        //   console.log('isFieldValidfff', isFieldValid);
        //   // Update the overall form validity based on the validation result for the current field
        //   isDynamicFormValid = isDynamicFormValid && isFieldValid;
        // }

        //de modificat
      }
    } else if (currentStep.step === 3) {
      isLastStep = true;
    }

    const isFormValid = await trigger(
      stepFields[currentStep.step][currentStep.substep],
    );

    if (isFormValid && isDynamicFormValid) {
      prevStep.current.push(currentStep);
      clearErrors();
      setCurrentStep((prevState) => {
        return {
          ...prevState,
          step: isLastStep ? 4 : prevState.step + 1,
          substep,
        };
      });
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };
  const handlePrevious = () => {
    if (currentStep.step > 1) {
      setCurrentStep({
        step: prevStep.current[prevStep.current.length - 1].step,
        substep: prevStep.current[prevStep.current.length - 1].substep,
      });
      prevStep.current.pop();
    }
  };

  const handleFileUpload = (field, files) => {
    Object.keys(files).forEach((fileId) => {
      files[fileId]['isDirty'] = true;
    });

    setError(field, {
      type: null,
      message: '',
    });
    let updatedFiles;

    setDirtyFields((prevState) => ({
      ...prevState,
      [field]: true,
    }));

    setUploadedFiles((prevState) => {
      updatedFiles = [...(prevState?.[field] || []), ...files];
      setValue(field, updatedFiles);
      return {
        ...prevState,
        [field]: [...(prevState?.[field] || []), ...files],
      };
    });
  };

  const handleRemoveFile = (field, index) => {
    setUploadedFiles((prevState) => {
      const uplodedFilesByFieldCopy = [...prevState[field]];

      const removedFileArr = uplodedFilesByFieldCopy.splice(index, 1);

      setValue(field, uplodedFilesByFieldCopy);
      filesToRemove.current.push(removedFileArr[0].name);
      return {
        ...prevState,
        [field]: uplodedFilesByFieldCopy,
      };
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const allData = getValues();
    const data = {
      ...getFormData(
        allData,
        [...prevStep.current, { step: 4, substep: 1 }],
        dirtyFields,
      ),
      ...(filesToRemove.current.length && {
        filesToRemove: filesToRemove.current,
      }),
    };
    const isFormValid = await trigger(
      stepFields[currentStep.step][currentStep.substep],
    );
    if (isFormValid) {
      if (data.patientMedicalDocuments instanceof FileList) {
        const patientMedicalDocumentsArray = Array.from(
          data.patientMedicalDocuments,
        );

        const firstMedicalDocument = patientMedicalDocumentsArray[0];
      }

      try {
        if (id) {
          if (Object.keys(data).length > 0) {
            await updateCase({
              id,
              body: {
                ...data,
                patientRequirement: allData.patientRequirement,
                filesToRemove: filesToRemove.current,
                ...(allData.requestedCase && { requestedCase: false }),
              },
            }).unwrap();
          }
        } else if (!id) {
          await addCase(data).unwrap();
        }
        navigate('/cases');
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <>
      {(isLoading || isLazyCaseLoading || isUpdateLoading) && <Spinner />}

      <DashboardLayout>
        <DashboardNavbar position='absolute' />
        <section>
          <form className='add-case__form' onSubmit={onSubmit}>
            <AddCaseContent
              currentStep={currentStep}
              register={register}
              errors={errors}
              trigger={trigger}
              setValue={setValue}
              setDirtyFields={setDirtyFields}
              watch={watch}
              handleFileUpload={handleFileUpload}
              handleRemoveFile={handleRemoveFile}
              uploadedFiles={uploadedFiles}
              control={control}
            />
            <div className='add-case__footer'>
              <Grid container spacing={2} justifyContent='flex-start'>
                {id && (
                  <Grid
                    item
                    xs={12}
                    md={3}
                    lg={2}
                    display='flex'
                    justifyContent='center'
                  >
                    <MDButton
                      type='button'
                      size='medium'
                      onClick={() => {
                        if (location?.state?.prevUrl) {
                          navigate(location.state.prevUrl);
                        }
                        navigate('/');
                      }}
                      color='error'
                    >
                      Anulează
                    </MDButton>
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  md={3}
                  lg={5}
                  display='flex'
                  justifyContent='center'
                >
                  <MDButton
                    type='button'
                    size='medium'
                    onClick={handlePrevious}
                    disabled={currentStep.step === 1}
                  >
                    Înapoi
                  </MDButton>
                </Grid>
                {currentStep.step < 4 && (
                  <Grid
                    item
                    xs={12}
                    md={3}
                    lg={5}
                    display='flex'
                    justifyContent='center'
                  >
                    <MDButton
                      width='3rem'
                      size='medium'
                      type='button'
                      onClick={handleNext}
                    >
                      Înainte
                    </MDButton>
                  </Grid>
                )}

                {currentStep.step === 4 && (
                  <Grid
                    item
                    xs={12}
                    md={3}
                    lg={5}
                    display='flex'
                    justifyContent='center'
                  >
                    <MDButton width='3rem' size='medium' type='submit'>
                      Trimite
                    </MDButton>
                  </Grid>
                )}
              </Grid>
            </div>
          </form>
        </section>
      </DashboardLayout>
    </>
  );
};

export default AddCase;
