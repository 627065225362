import React, { useState, useRef } from 'react';
import { useParams } from 'react-router';
// @mui material components
import Card from '@mui/material/Card';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from '../../components/MDButton';

import Attachment from '../../components/attachment';

import Spinner from '../../components/spinner';

import { useAddCaseAttachmentsMutation } from '../../redux/api/case';
import useHandleErrors from '../../hooks/useHandleErrors';

import './style.scss';

const Attachments = ({ list = [] }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);

  const hiddenFileInput = useRef(null); // ADDED

  const handleClick = (event) => {
    hiddenFileInput.current.click(); // ADDED
  };

  const { id } = useParams();

  const [addAttachments, { isLoading, error }] =
    useAddCaseAttachmentsMutation();
  useHandleErrors(error);

  const handleAddAttachments = (e) => {
    const files = e.target.files;
    try {
      addAttachments({ attachments: e.target.files, id }).unwrap();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      {isLoading && <Spinner />}

      <Card sx={{ height: 'auto' }}>
        <MDBox
          pt={2}
          px={2}
          display='flex'
          justifyContent='space-between'
          alignItems='center'
        >
          <MDTypography variant='h6' fontWeight='medium'>
            Attachments
          </MDTypography>
          <MDButton
            variant='outlined'
            color='info'
            size='small'
            onClick={handleClick}
          >
            <input
              className='file-input-attachment'
              type='file'
              ref={hiddenFileInput}
              onChange={handleAddAttachments}
              multiple
            ></input>
            Upload file
          </MDButton>
        </MDBox>
        <MDBox p={2}>
          <MDBox
            component='ul'
            display='flex'
            flexDirection='column'
            p={0}
            m={0}
          >
            {list.length > 0 &&
              list.map((item) => (
                <Attachment key={item} name={item} caseId={id} />
              ))}
            {/* <Attachment date="February, 10, 2021" id="#RV-126749" price="$250" />
          <Attachment date="April, 05, 2020" id="#QW-103578" price="$120" />
          <Attachment date="June, 25, 2019" id="#MS-415646" price="$180" />
          <Attachment date="March, 01, 2019" id="#AR-803481" price="$300" noGutter /> */}
          </MDBox>
        </MDBox>
      </Card>
    </>
  );
};

export default Attachments;
