import React from 'react';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from '../input/index.jsx';
import RadioButtonsList from '../radio-buttons-list/index.jsx';
import {
  patientRelationship,
  patientRequirement,
  STYLES as STYLE,
} from '../../constants/index.js';
import Button from '../button/index.jsx';
import InputFile from '../input-file/index.jsx';
import CaseSubstepOne from './case-substep-one/index.jsx';
import CaseSubstepTwo from './case-substep-two/index.jsx';
import CaseSubstepThree from './case-substep-three/index.jsx';

// const schema = Yup.object({
//   firstAndLastName: Yup.string().required(),
//   radioGroup: Yup.string().required(),
//   patientRequirement: Yup.string().required(),
// });
const CaseStepTwo = ({
  register,
  errors,
  trigger,
  setValue,
  setDirtyFields,
  watch,
  substep,
  handleFileUpload,
  handleRemoveFile,
  uploadedFiles,
  control,
}) => {
  const substeps = {
    1: (
      <CaseSubstepOne
        register={register}
        errors={errors}
        trigger={trigger}
        setValue={setValue}
        setDirtyFields={setDirtyFields}
        watch={watch}
        handleFileUpload={handleFileUpload}
        handleRemoveFile={handleRemoveFile}
        uploadedFiles={uploadedFiles}
        control={control}
      />
    ),
    2: (
      <CaseSubstepTwo
        register={register}
        errors={errors}
        trigger={trigger}
        setValue={setValue}
        setDirtyFields={setDirtyFields}
        watch={watch}
        handleFileUpload={handleFileUpload}
        handleRemoveFile={handleRemoveFile}
        uploadedFiles={uploadedFiles}
        control={control}
      />
    ),
    3: (
      <CaseSubstepThree
        register={register}
        errors={errors}
        trigger={trigger}
        setValue={setValue}
        setDirtyFields={setDirtyFields}
        watch={watch}
        handleFileUpload={handleFileUpload}
        handleRemoveFile={handleRemoveFile}
        uploadedFiles={uploadedFiles}
        control={control}
      />
    ),
  };

  return <>{substeps[substep]}</>;
};

export default CaseStepTwo;
